import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import {
  APPIDS,
  COURSES_ILOS_ROUTE_NAME,
  COURSES_ROUTE_NAME,
  COURSE_QUESTIONS_STASTICS,
  EXAM_GENERATION_ROUTE_NAME,
  QUESTIONS_ROUTES_NAMES,
  RESERVE_EXAM_ROUTE_NAME,
  REVIEW_QUESTIONS_ROUTES_NAMES,
  SUBJECT_ROUTE_NAME,
} from '@/enums';
import { SUBJECT_ROUTES } from './subject.router';
import { COURSES_ROUTES } from './courses.router';
import { QUESTIONS_ROUTES } from './questions.router';
import { EXAM_GENERATION_ROUTES } from './ExamGeneration.router';
import { REVIEW_QUESTIONS_ROUTES } from './reviewQuestions.router';
import { REPORTS_ROUTES } from './reports.router';
import { COURSE_ILOS_ROUTES } from './AddIlos.router';
import routesEnums from '../enums/routes.enum';
import { RESERVE_EXAM_ROUTES } from './reserveExam.router';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

function courseIdRedirect(courseListRoute, appViewRoute, appId) {
  const courseId = store.state.course.selectedCourseId;
  const appFilters = store.state.filters.appFilters[appId];
  const { courseFilters } = store.state.filters;
  if (courseId) {
    return { name: appViewRoute, params: { courseId }, query: appFilters };
  }
  return { name: courseListRoute, query: courseFilters };
}

const routes: Array<RouteConfig> = [

  {
    path: '/',
    name: 'layout',
    redirect: 'dashboard',
    component: () => import('@/views/mainLayout.vue'),
    children: [
      {

        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard.vue'),
      },
      {
        path: 'subject',
        component: () => import('@/views/subject.view.vue'),
        redirect: () => courseIdRedirect(
          SUBJECT_ROUTE_NAME.COURSE_LIST,
          SUBJECT_ROUTE_NAME.LIST,
          APPIDS.SUBJECTS,
        ),
        children: SUBJECT_ROUTES,
        meta: { requiresAuth: true },

      },
      {
        path: 'course-ilos',
        name: 'course-ilos',
        redirect: () => courseIdRedirect(
          COURSES_ILOS_ROUTE_NAME.COURSES_LIST,
          COURSES_ILOS_ROUTE_NAME.COURSES_ILOS_VIEW,
          APPIDS.COURSE_ILOS,
        ),
        component: () => import('@/views/addILos.view.vue'),
        children: COURSE_ILOS_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'courses',
        name: 'courses',
        redirect: () => courseIdRedirect(
          COURSES_ROUTE_NAME.LIST,
          COURSES_ROUTE_NAME.VIEW,
          APPIDS.COURSES,
        ),
        component: () => import('@/views/courses.view.vue'),
        children: COURSES_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'exam-generation',
        name: 'exam-generation',
        redirect: () => courseIdRedirect(
          EXAM_GENERATION_ROUTE_NAME.COURSES_LIST,
          EXAM_GENERATION_ROUTE_NAME.EXAMS_LIST,
          APPIDS.EXAM_GENERATION,
        ),
        component: () => import('@/views/examGeneration.view.vue'),
        children: EXAM_GENERATION_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'reserve-exam',
        name: 'reserve-exam',
        redirect: () => courseIdRedirect(
          RESERVE_EXAM_ROUTE_NAME.COURSES_LIST,
          RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAMS_LIST,
          APPIDS.RESERVE_EXAM,
        ),
        component: () => import('@/views/reserveExam.view.vue'),
        children: RESERVE_EXAM_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'questions',
        name: 'questions',
        redirect: () => courseIdRedirect(
          QUESTIONS_ROUTES_NAMES.COURSES_LIST,
          QUESTIONS_ROUTES_NAMES.QUESTIONS_LIST,
          APPIDS.CREATE_QUESTIONS,
        ),
        component: () => import('@/views/questions.view.vue'),
        children: QUESTIONS_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'review-questions',
        name: 'review-questions',
        redirect: () => courseIdRedirect(
          REVIEW_QUESTIONS_ROUTES_NAMES.COURSES_LIST,
          REVIEW_QUESTIONS_ROUTES_NAMES.LIST,
          APPIDS.REVIEW_QUESTIONS,
        ),
        component: () => import('@/views/reviewQuestions.view.vue'),
        children: REVIEW_QUESTIONS_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'reports/course-questions-statistics',
        name: 'course-questions-statistics',
        redirect: () => courseIdRedirect(
          COURSE_QUESTIONS_STASTICS.COURSES_LIST,
          COURSE_QUESTIONS_STASTICS.VIEW,
          APPIDS.COURSE_QUESTIONS_STASTICS,
        ),
      },
      {
        path: 'reports',
        name: 'reports',
        redirect: '/reports/questions-status-stats',
        component: () => import('@/views/reports.view.vue'),
        children: REPORTS_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'reports',
        name: 'reports',
        redirect: '/reports/exams-creators',
        component: () => import('@/views/reports.view.vue'),
        children: REPORTS_ROUTES,
        meta: { requiresAuth: true },
      },
      {
        path: 'profile/:username',
        name: 'profile',
        component: () => import('@/views/profile.view.vue'),
      },
      {
        path: 'profileEdit/:username',
        name: 'profileEdit',
        component: () => import('../components/profile/editProfile.component.vue'),
      },
      {
        path: 'staff/updateCourses',
        name: 'updateStaffCourses',
        component: () => import('@/views/updateStaffCourses.view.vue'),
      },
    ],

  },
  {
    path: '/notFound',
    name: 'notFound',
    component: () => import('@/views/notFound.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/resetPassword.view.vue'),
  },
  {
    path: '/myMansLogin/:token',
    name: 'myMansLogin',
    component: () => import('@/views/myMansLogin.view.vue'),
  },
  {
    path: '*',
    redirect: 'notFound',
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function patchRouterMethod(methodName) {
  router[`old${methodName}`] = router[methodName];
  router[methodName] = (location) => router[`old${methodName}`](location).catch((error) => {
    if (error.name === 'NavigationDuplicated') {
      return router.currentRoute;
    }
    throw error;
  });
}

patchRouterMethod('push');
patchRouterMethod('replace');

router.beforeEach((to, from, next) => {
  if (store.state.auth.resetPassword && to.name !== 'ResetPassword') {
    const redirect = from.query.redirect ? `${from.query.redirect}` : '/';
    return next(`/resetPassword?redirect=${redirect}`);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const appToMatchRouteIndex = (to.matched[1].name === 'reports') ? 2 : 1;
    const splittedPath = (to.matched[appToMatchRouteIndex]?.path)
      ? to.matched[appToMatchRouteIndex].path.split('/') : [];
    const route = (appToMatchRouteIndex === 2) && (splittedPath.length > 3)
      ? splittedPath.slice(0, 3).join('/') : to.matched[appToMatchRouteIndex].path;
    const currentRouteName = to.name || '';

    const currentApp = routesEnums.find((c) => c.path === route);
    if (!currentApp) {
      return next('/notFound');
    }
    const appId = currentApp.id;
    const activeChildRight = currentApp.childrensScopesRight[currentRouteName];
    const splittedApp = appId.split('.');
    store.commit('selectApp', {
      appId,
      activeChildRight: activeChildRight || '',
      subSystemId: `${splittedApp[0]}.${splittedApp[1]}.`,
    });
    store.commit('openApps');
    return next();
  }
  return next(); // make sure to always call next()!
});

export default router;
