

















































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import emitter from '@/shared/utils/emitter';
import { ScopeComponentInterface } from '../types/general.type';

@Component
export default class ScopeTree extends Vue implements ScopeComponentInterface {
  @Prop({ default: false }) readonly lastLevel!: boolean;

  @Prop({ default: false }) readonly departmentLevel!: boolean;

  @Prop({ default: false }) readonly facultyLevel!: boolean;

  @Prop({ default: false }) readonly viewOnly!: boolean;

  @Prop({ default: false }) readonly reset!: boolean;

  get items() {
    return this.$store.state.scope.scopeList;
  }

  mounted() {
    if (this.reset) {
      this.$store.commit('resetScope');
    }
    this.setTreeLevel(this.lastLevel, this.departmentLevel);
  }

  setTreeLevel(isLastLevel, isDepartmentLevel) {
    this.$store.commit('setTreeLastLevel', isLastLevel);
    this.$store.commit('setTreeDepartmentLevel', isDepartmentLevel);
  }

  scopeClick() {
    if (this.viewOnly) {
      return;
    }
    this.$store.commit('openDrawer');
    this.$store.commit('openScope');
  }

  static setFocus() {
    emitter.emit('scopFocus');
  }

  @Watch('$store.state.scope.selectedScopeId', { deep: true })
  onScopChange(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.$emit('onScopeChange', this.$store.state.scope.selectedScopeId);
    }
  }
}
