




































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CoursesListFiltersComponent from '@/components/filters/coursesListFilters.component.vue';
import { Subject } from '@/types/subject.types';
/* eslint-disable import/no-cycle */
import CourseListMixin from '@/mixins/courseList.mixin';
import ScopeComponent from '@/components/scope.component.vue';
import DialogComponent from '@/components/dialog.component.vue';
import CourseActivationDialog from '@/components/courses/coursesActivation.dialog.vue';
import { COURSES_ROUTE_NAME } from '@/enums';
import Toaster from '@/mixins/toaster.mixin';

@Component({
  components: {
    CoursesListFiltersComponent,
    ScopeComponent,
    DialogComponent,
    CourseActivationDialog,
  },
})
export default class SharedCourseList extends mixins(CourseListMixin, Toaster) {
  title = 'Test Component';

  addUrl = COURSES_ROUTE_NAME.ADD;

  instructorsUrl = COURSES_ROUTE_NAME.INSTRUCTORS;

  subjects: Subject[] = [];

  selectedSubjects: string[] = [];

  id = 0;

  courseId: string | number = '';

  showDialog = false;

  subjectsToBeActivated = false;

  active = false;

  get headers() {
    const headersArray = [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.courseName'),
        sortable: true,
        value: 'courseName',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.studyPlan'),
        sortable: true,
        value: 'studyPlan',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.courseType'),
        sortable: false,
        align: 'center',
        value: 'courseType',
        class: this.headerClass,
      },
    ];
    if (this.showAction) {
      headersArray.push({
        text: '',
        sortable: false,
        align: 'center',
        value: 'actions',
        class: this.headerClass,
      });
    }
    return headersArray;
  }

  async customOptionUpdate(options) {
    await this.onOptionUpdate(options);
    if (this.$store.state.course.selectedCourseId && this.$store.state.course.selectedCourseId !== '') {
      this.openCourseSubjectList({ courseId: this.$store.state.course.selectedCourseId, courseName: this.$store.state.course.selectedCourseName });
    }
  }

  onCourseClick(courseId: string) {
    this.$router.push({
      name: 'subject-list',
      params: { courseId },
    });
  }

  openCourseSubjectList(course) {
    this.$store.commit('selectCourse', {
      course,
    });
    this.$emit('rowClicked', {
      queries: this.queries,
      courseId: course.courseId,
    });
  }

  showActivation(course) {
    this.courseId = course.courseId;
    this.active = !course.active;
    this.showDialog = true;
  }

  async activate() {
    this.showDialog = false;
    this.subjectsToBeActivated = true;
  }

  closeModal(active) {
    this.refreshTable(active);
    this.subjectsToBeActivated = false;
  }

  refreshTable(active) {
    const index = this.courseList.findIndex(
      (c) => c.courseId === this.courseId,
    );
    const item = { ...this.courseList[index], active };

    this.courseList = [
      ...this.courseList.slice(0, index),
      item,
      ...this.courseList.slice(index + 1),
    ];
  }

  cancel() {
    this.refreshTable(this.active);
    this.showDialog = false;
  }
}
