/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */

const arTranslate = {
  login: 'تسجيل دخول',
  password: 'كلمة المرور',
  usrNameInput: 'اسم المستخدم او البريد الالكتروني',
  qbank: ' بنك الاسئلة',
  lang: 'English',
  forgetPassword: 'هل نسيت كلمة المرور ؟',
  requiredField: 'هذا الحقل مطلوب',
  maxLetters: 'يبغي الا تزيد عدد حروف هذا الحقل عن ',
  minLetters: 'يبغي الا تقل عدد حروف هذا الحقل عن ',
  maxValue: 'يجب ان لا تزيد القيمه عن ',
  minValue: 'يجب ان لا تقل القيمه عن ',
  maxLessValue: 'يجب ان تكون القيمه أقل من ',
  equalValue: 'يجب ادخال رقم يساوي ',
  invalidInputData: 'يرجى ادخال البيانات بشكل صحيح',
  testToken: 'تست توكين',
  questionsStatus: 'حالة الاسئلة',
  questionsCount: 'عدد الاسئلة',
  optionsCount: 'عدد الإختيارات',

  chartPrograms: 'تطبيقات',
  print: 'طباعة',
  examsStatus: 'حالة اختبارات التصحيح الالكترونى',
  examsCount: 'عدد الاختبارات',
  courses: 'المقررات',
  subjects: 'الموضوعات',
  electronicCorrectionExams: 'التصحيح الإلكتروني',
  questions: 'الاسئلة',
  exams: 'الإختبارات',
  chartRights: 'المقررات المتاحة على مستوى النظام',
  Logout: 'تسجيل خروج',
  langShortCut: 'En',
  ILOS: 'نواتج التعلم المستهدفة',
  Title: 'العنوان',
  STATUS: 'الحاله',
  lectureCount: 'عدد المحاضرات',
  btn_save: 'حفظ',
  btn_edit: 'تعديل',
  Active: 'فعال',
  inactive: 'غير فعال ',
  Yes: 'نعم',
  No: 'لا',
  TRUE: 'صح',
  FALSE: 'خطأ',
  Subject: 'الموضوع',
  Subjects: 'الموضوعات',
  Search_by: 'بحث بستخدام ',
  Required: 'برجاء ادخال بيانات',
  input_number_validation: 'برجاء ادخال رقم صحيح',
  Scope: 'المجال',
  No_subjects: 'لايوجد موضوعات',
  Add_subject: 'اضافة موضوع',
  add: 'اضافه',
  delete: 'حذف',
  isCourseActive: 'هذا المقرر غير فعال',

  studentsCount: 'عدد الطﻻب',
  instructions: {
    validExcel: ' المقبوله تكون من نوع xlsx-xls فقط ويجب الا يزيد عن 5 ميجا',
    validExcelColumn_A: 'العمود A يجب ان يحتوى على اسم المقرر بالغة العربيه',
    validExcelColumn_B: 'العمود B يجب ان يحتوى على اسم المقرر بالغة الانجليزيه ',
    validExcelColumn_C: 'العمود C يجب ام يحتوى على اسم الائحة باللغه العربيه',
    validExcelColumn_D: 'العمود D يجب ان يحتوى على اسم الائحة باللغة الانجليزيه',
    validExcelColumn_E: 'العمود E يجب ان يحتوى على رقم الائحة ',
    validExcelColumn_F: 'العمود F اختيارى و يحتوى على كود المقرر',
    validExcelOrder: 'يجب ادخال البيانات بترتيب الاعمده (A-B-C-D-E-F) واى بيانات اخرى لا تعد صحيحه',
  },
  QuestionImportModelInstructions: {
    MODEL_INSTRUCTIONS: '- يجب ان يكون الملف  بصيغة XML وتم استخراجه من نظام Moodle',
    MOODLE_INSTRUCTIONS_2:
      '- ان يحتوى الملف على أنواع الاسئلة الاتيه (صح أم خطأ - مقالى -اختيار من متعدد).',
    MOODLE_INSTRUCTIONS_3: '- ان يكون تشفير محتويات الملف UTF-8.',
  },
  QuestionImportQmansInstructions: {
    MOODLE_INSTRUCTIONS_3: '- ان يكون تشفير محتويات الملف UTF-8.',
    QMANS_INSTRUCTIONS: '- يجب ان يكون الملف بصيغة XML وتم استخراجه من نظام Qmans',
    QMANS_INSTRUCTIONS_2: '- يجب الا تزيد مساحة الملف عن 5 MB',
    QMANS_DOC_INSTRUCTIONS: {
      FILE_SIZE: '- مساحة الملف يجب ان لا تزيد عن 5MB.',
      FILE_TYPE: '- الملف يجب ان يكون من نوع (.docx) او (.txt) فقط.',
      QUESTIONS_TYPES: '- الاسئلة يجب ان تكون من نوع (اختر من متعدد) او (متعدد الاختيارات) فقط.',
      QUESTIONS_START: '- يجب أن يبدأ كل سؤال ب (----------).',
      QUESTIONS_ANSWERS: '- يجب أن تبدأ كل إجابة بحروف كبيرة متبوعه ب (:) مثل [(:A), (:B), (:C), (:D), (:E), (:ANSWER)].',
      CORRECT_ANSWERS: '- يجب أن تكون الإختيارات الصحيحه موجودة في الإختيارات السابقه بنفس شكل الحروف.',
      MULTI_ANSWERS: '- إذا كان هناك أكثر من إجابة يفصل بين الإجابات بعلامة (,).',
      QUESTION_TYPE: '- يعتمد نوع السؤال على عدد الإجابات التي يتم إدخالها على كل سؤال.',
    },
    QMANS_EXCEL_INSTRUCTIONS: {
      FILE_TYPE: '- الملف يجب ان يكون من نوع (.xlsx) فقط.',
      QUESTIONS_STEM: '- العمود (A) يحتوي على نص السؤال',
      QUESTIONS_CORRECT: `
        - العمود (B) يحتوي على ترتيب الاجابات الصحيحة (1:5)
        و يمكن فصل الاجابات بفاصلة في حالة السؤال متعدد الاختيارات كالتالي (1,3)`,
      QUESTIONS_OPTIONS: '- الاعمدة من (C) الى (G) تحتوي على اختيارات السؤال',
    },
    QMANS_TXT_INSTRUCTIONS: {
      FILE_TYPE: '- الملف يجب ان يكون من نوع (.txt) فقط.',
      QUESTIONS_TYPES: '- يمكن أن يحتوى الملف على أنواع الاسئلة الاتيه (صح أم خطأ -اختيار من متعدد - متعدد الاختيارات).',
    },
  },
  titles: {
    CURRENT_QUESTION: 'الإصدار الحالي',
    OLD_VERSIONS: 'الإصدارات السابقة والتقارير',
    COURSE_STATISTICS: 'إحصائيات المقرر',
    ELECTRONIC_EXAMS: 'الإختبارات الإلكترونية',
    subQuestionsFirstColumn: 'الاسئلة الفرعية ( العمود الاول)',
    answersSecondColumn: 'الإجابات  ( العمود الثاني)',
    reservationActions: {
      add: 'إضافة حجز',
    },
    viewRequestDetails: 'عرض تفاصيل الطلب',
    viewImportDetails: 'تفاصيل سحب الأسئلة',
    COURSE_QUESTIONS_STATISTICS: 'تقرير عن تنوع درجات صعوبة الاسئله لمقرر ',
    forExam: ' لامتحان ',
    examModelDataForNumber: 'بيانات نموذج رقم ',
    ExamDetails: 'بيانات النموذج',
    ExamPaper: 'ورقه النموذج',
    ExamMatrix: 'مخطط النموذج',
    AddExam: 'اضافه اختبار',
    courses: 'المقررات',
    addCourse: 'اضافة مقرر',
    infoMessage: 'رسالة توضيحية',
    sessionExpiryMessage: 'اوشكت فترة الدخول على الانتهاء لن تتمكن من الاحتفاظ بالمدخلات ، لاستكمال العمل على النظام برجاء مد الفترة بالضغط على زر تأكيد قبل نهاية زمن الرسالة.',
    details: 'التفاصيل',
    courseDetails: 'تفاصيل المقرر',
    addedCourses: 'المقررات المضافة',
    editedCourses: 'المقررات التي تم تعديلها',
    inactiveCourses: 'المقررات التي تم الغاء تفعيلها',
    confirmMessage: 'رسالة تأكيد',
    sessionExpiryWarning: 'تنبيه نهاية فترة الدخول',
    createQuestions: 'وضع الاسئلة',
    QuestionsData: 'بيانات الاسئلة',
    Exams: 'الاختبارات',
    ReserveExam: 'حجز الإختبارات',
    questionDetails: 'تفاصيل السؤال',
    addQuestion: 'اضافة سؤال',
    questionAction: {
      add: 'إضافة سؤال',
      copy: 'نسخ سؤال',
      edit: 'تعديل سؤال',
      addCaseStudy: 'إضافة سؤال دراسة حاله',
      copyCaseStudy: 'نسخ سؤال دراسة حاله',
      editCaseStudy: 'تعديل سؤال دراسة حاله',
    },
    questionReview: 'مراجعة السؤال',
    reviewQuestion: 'مراجعة الاسئلة',
    questionImport: 'إضافة أسئله لمقرر',
    FileInstructions: 'تعليمات ملف السحب',
    ImportInstructors: 'اضافة اعضاء هيئة تدريس',
    chooseLang: 'اختر لغة',
    questionsStatusStatsReport: 'تقرير احصائيات حالة الاسئلة',
    examsCreatorsReport: 'تقرير واضعى الاختبارات الالكترونية',
    examAction: {
      add: 'إضافة نموذج',
      edit: 'تعديل النموذج',
      copy: 'نسخ النموذج',
      mainQuestionTitle: 'عنوان السؤال الرئيسي',
    },
    examMatrix: 'مخطط الاختبار',
    showAddQuestionDialog: 'إضافة سؤال',
    EXAM_MODEL_STATISTICS: 'احصائيات النموذج',
    IMPORT_QUESTIONS_DIALOG: 'سحب الأسئلة',
  },

  tableHeaders: {

    subILOCode: 'الكود',
    subIloName: 'ناتج التعلم الفرعي ',
    examTitle: 'عنوان الامتحان',
    academicYear: 'العام الاكاديمي',
    examType: 'النوع',
    examStatus: 'حاله الامتحان',
    semester: 'الفصل الدراسي',
    turn: 'الدور',
    createdAt: 'تاريخ الإنشاء',
    index: 'التسلسل',
    courseName: 'اسم المقرر',
    studyPlan: 'اسم اللائحة',
    courseType: 'نوع المقرر',
    IHId: 'كود ابن الهيثم',
    actions: 'العمليات',
    studyPlanId: 'رقم اللائحة',
    courseCode: 'كود المقرر',
    questionTitle: 'عنوان السؤال',
    ILOs: 'نواتج التعلم المستهدفة',
    questionType: 'نوع السؤال',
    subject: 'الموضوع',
    questionStatus: 'حالة السؤال',
    questionReviewDate: 'تاريخ طلب مراجعة السؤال',
    notes: 'ملاحظات',
    name: 'الاسم',
    reason: 'السبب',
    questionLanguage: 'لغة السؤال',

    complete: 'مكتمل',
    incomplete: 'غير مكتمل',
    pendingReview: 'تحت المراجعة',
    approved: 'تم الاعتماد',
    rejected: 'تم الرفض',
    deleted: 'تم الحذف',

    totalCount: 'الاجمالي',
    totalQuestionsCount: 'اجمالي عدد الاسئلة',
    totalDegreesCount: 'اجمالي مجموع الدرجات',
    questionsAndDegrees: 'الاسئلة و الدرجات',
    questionsCount: 'عدد الاسئلة',
    degreesSum: 'مجموع الدرجات',
    degreesMid: 'متوسط درجة السؤال',
    timeMid: 'متوسط وقت السؤال (بالدقيقة)',
    relativeWeights: 'الأوزان النسبية للموضوعات',
    questionNumber: 'عدد الأسئلة',
    COUNT_OF_ANSWERS: 'عدد الاجابات',
    subjectWeight: 'الوزن النسبي للموضوع',
    iloWeight: 'الوزن النسبي لناتج التعلم',
    totalDegreesSum: 'إجمالي مجموع الدرجات',

    reserveTableExamType: 'نوع الإختبار',
    requestDate: 'تاريخ الطلب',
    day: 'اليوم',
    requestStatus: 'حالة الطلب',
    center: 'مركز الاختبار',
    errorMessage: 'رسالة الخطأ',
    questionData: 'بيانات السؤال',
    PRIORITY: 'الأولوية',
    LIMITED_SECTIONS_CAPACITY: 'سعة اللجان المحدودة',
    UNLIMITED_SECTIONS_CAPACITY: 'سعة اللجان غير المحدودة',

    DIFFICULTY_INDEX: 'معامل الصعوبة',
    DISCRIMINATION_INDEX: 'معامل التمييز',

    MIN_TIME: 'أقل وقت (دقيقة)',
    MAX_TIME: 'أقصى وقت (دقيقة)',
    MEAN: 'المتوسط (دقيقة)',
    MEDIAN: 'الوسيط (دقيقة)',
    MODE: 'المنوال (دقيقة)',
    STANDARD_DEVIATION: 'الانحراف المعياري (دقيقة)',
    COEFFICIENT_OD_VARIATION: 'معامل الاختلاف',

    DIFFICULTY_INDEX_AVERAGE: 'متوسط معامل الصعوبة',
    DISCRIMINATION_INDEX_AVERAGE: 'متوسط معامل التمييز',
    MIN_TIME_AVERAGE: 'متوسط أقل وقت (دقيقة)',
    MAX_TIME_AVERAGE: 'متوسط أقصى وقت (دقيقة)',
    MEAN_AVERAGE: 'متوسط المتوسط (دقيقة)',
    MEDIAN_AVERAGE: 'متوسط الوسيط (دقيقة)',
    MODE_AVERAGE: 'متوسط المنوال (دقيقة)',
    STANDARD_DEVIATION_AVERAGE: 'متوسط الانحراف المعياري (دقيقة)',
    COEFFICIENT_OD_VARIATION_AVERAGE: 'متوسط معامل الاختلاف',

    SUCCESS_RATE: 'نسبة النجاح',
    DEGREE_GIVEN_TO_ALL: 'الدرجة معطاه للجميع',

    DATE_TIME_FROM: 'التاريخ / الوقت من',
    DATE_TIME_TO: 'التاريخ / الوقت إلى',
  },

  labels: {
    CONFIRMATION_RESTRICTIONS: 'يجب الموافقة على الآتي قبل تصدير الاختبار',
    CHOOSE_SECTORS: 'حدد القطاعات التي تريد توزيع الاختبار عليها',
    DISTRIBUTION_WARNING: 'بعد الموافقة على التوزيع لا يمكن إعادة التوزيع أو تعديل بيانات الاختبار والنماذج',
    MODEL_CODE_EXAMPLE: 'مثال لكود نموذج',
    MODEL_CODE: 'كود النموذج',
    ITEM_ANALYSIS: 'تحليل عناصر الاختبار',
    ANSWER_TiME_DISTRIBUTION: 'توزيع وقت الاجابة',
    QUESTION_SUCCESS_RATE: 'نسب نجاح السؤال',

    STUDENTS_COUNT: 'عدد الطﻻب',
    EXTRA_STUDENTS_COUNT: 'عدد الطلاب الإضافي',
    MODIFIED_STUDENTS_COUNT: 'إجمالي عدد الطلاب المطلوب',
    TOTAL_STUDENTS_COUNT: 'العدد الكلي للطلاب = عدد الطلاب المسحوب + عدد الطلاب الإضافي',
    TOTAL_STUDENTS_HINT: 'قم بإدخال قيمة فقط في حالة إن كنت ترغب في عدد طلاب مختلف',
    TOTAL_STUDENTS: 'العدد الكلي للطلاب',
    STUDENTS_TYPE: 'نوع الطﻻب',
    centers: 'مراكز الاختبار',
    CENTERS_CAPACITY: 'مراكز الاختبار (سعة لجان محدودة) (سعة لجان غير محدودة)',
    LIMITED_SECTIONS_CAPACITY: 'سعة لجان محدودة',
    UNLIMITED_SECTIONS_CAPACITY: 'سعة لجان غير محدودة',
    signature: 'معتمد البيان',
    requestTimeFrom: ' وقت الطلب من',
    requestTimeTo: ' وقت الطلب الى',
    STATISTICS_TYPE: 'نوع الاحصائية',
    correctAnswerIndex: 'رقم الإجابة الصحيحة',
    ExamBasicData: 'بيانات الامتحان الاساسيه',
    AddQuestions: 'إضافة أسئلة',
    languages: 'اللغه',
    ExamMatrix: 'مصفوفه الامتحان',
    next: 'التالي',
    examDate: 'تاريخ الاختبار',
    modelsMatchOptions: 'تطابق النماذج',
    ExamTitle: 'عنوان الاختبار',
    degree: 'الدرجة',
    exposureLimit: 'عدد مرات استخدام السؤال',
    instructor: 'أستاذ الماده',
    pervious: 'السابق',
    semesters: 'الفصل الدراسي',
    turns: 'الدور',
    shuffleOption: 'اختيار الخلط',
    examSignature: 'توقيع المدرس',
    addDegree: 'له درجات اضافيه',
    examPeriod: 'الفترة',
    timeInMinutes: 'الوقت (دقيقة)',
    trainingOptions: 'اسئله التدريب',
    academicYears: 'العام الاكاديمي',
    examStatus: 'حاله الامتحان',
    reserveExamStatus: 'حالة الطلب',
    center: 'مركز الإختبار',
    ExamType: 'نوع الاختبار',
    examDuration: 'مدة الاختبار',
    examTotalStudents: 'عدد الطلاب الكلي',
    examAttendedStudents: 'عدد الطلاب الحاضرين',
    VALID_EXPORTED_QUESTIONS_COUNT: 'عدد الأسئلة الصالحة',
    VALID_EXPORTED_QUESTIONS: 'الأسئلة الصالحة',
    NOT_VALID_EXPORTED_QUESTIONS_COUNT: 'عدد الأسئلة الغير صالحة',
    NOT_VALID_EXPORTED_QUESTIONS: 'الأسئلة الغير صالحة',
    login: 'تسجيل الدخول',
    syncCourses: 'تحديث المقررات',
    SYNC_ACADEMIC_COURSES: 'تحديث مقررات -عام اكاديمي',
    SYNC_PGS_COURSES: 'تحديث مقررات - دراسات عليا',
    SYNC_CREDIT_COURSES: 'تحديث مقررات - ساعات معتمده',
    about: 'عن النظام',
    process: 'عمليات النظام',
    services: 'الخدمات',
    users: 'المستخدمين',
    hintCaseStudy: 'جاري العمل عليها',
    hintMongoQues: 'لايمكن تصديره للتصحيح الإلكتروني',
    policy: 'سياسة النظام',
    startNow: 'ابدأ الان',
    sysSolution: 'الحل المتكامل للامتحانات الاكاديمية',
    qmansSys:
      'نظام Qmans هو نظام مبنى باستخدام تكنولوجيا الويب يهدف الى جمع أعضاء هيئة التدريس من أجل انشاء بنك أسئلة يتم من خلاله انشاء الاختبارات .   ',
    sysGoal:
      'يهدف النظام الى انشاء اختبارات طبقا لمعايير متفق عليها , ولتحقيق ذلك يوفر النظام مجموعة من الخدمات التى تمكن اعضاء هيئة التدريس من ادارة المقررات و الموضوعات و نواتج التعلم المستهدفة و الاسئلة التى يتم استخدامها فى انشاء الاختبارات .',
    readMore: 'قراءة المزيد',
    courseName: 'اسم المقرر',
    studyPlan: 'اسم اللائحة',
    courseType: 'نوع المقرر',
    IHId: 'كود ابن الهيثم',
    status: 'الحالة',
    active: 'فعال',
    inactive: 'غير فعال',
    noData: 'لا توجد بيانات',
    noExamsData: 'لا توجد بيانات اختبارات على المقرر',
    noScopeSelected: 'يجب اختيار المجال الصحيح',
    noCourseSelected: 'يجب اختيار المقرر',
    noValidQuestions: 'لا يوجد أسئلة صالحة للسحب',
    loading: 'جاري التحميل... برجاء الانتظار',
    addMethod: 'طريقة الاضافة',
    academicYear: 'العام الاكاديمي',
    addFromService: 'سحب من شئون الطلاب',
    addFromExcel: 'أضافة من ملف بيانات',
    addFromSystem: 'اضافه من خلال النظام',
    arCourseName: 'اسم المقرر بالعربية',
    enCourseName: 'اسم المقرر بالانجليزية',
    arStudyPlan: 'عنوان اللائحة بالعربية',
    enStudyPlan: 'عنوان اللائحة بالانجليزية',
    studyPlanId: 'رقم اللائحة',
    courseCode: 'كود المقرر',
    sortBy: 'ترتيب بواسطة',
    questionTitle: 'عنوان السؤال',
    examTitle: 'عنوان الإمتحان',
    ILOs: 'نواتج التعلم المستهدفة',
    questionType: 'نوع السؤال',
    subject: 'الموضوع',
    questionStatus: 'حالة السؤال',
    difficultyLevel: 'مستوى الصعوبة',
    DIFFICULTY_LEVEL_IN_EXAMS: 'متوسط مستوى الصعوبة في الاختبارات',
    AVERAGE_TIME_IN_EXAMS: 'متوسط الوقت في الاختبارات',
    NEED_TO_FIX: 'يحتاج إلى تحديث',
    TIME_AND_DIFFICULTY: 'الوقت ومستوى الصعوبة',
    TIME_AND_DIFFICULTY_LEVEL: 'الوقت ومستوى الصعوبة',
    TIME: 'الوقت',
    ilosHeader: 'نواتج التعلم',
    createdBy: 'انشأ بواسطة',
    select: '--اختر--',
    questionLanguage: 'لغة السؤال',
    questionDegree: 'درجة السؤال',
    questionTime: 'زمن السؤال',
    questionText: 'نص السؤال',
    questionAnswers: 'اجابات السؤال',
    questionAnswer: 'اجابة سؤال',
    correctAnswer: 'الاجابة الصحيحة',
    questionRubrics: 'مقاييس تدرج السؤال',
    questionExposureLimit: 'عدد مرات استخدام السؤال',
    evaluations: 'التقييم',
    examDifficulty: 'صعوبه الامتحان',
    mediumPercentage: 'متوسط',
    easy: 'سهوله',
    difficult: 'صعوبه',
    feedback: 'الملاحظات',
    training: 'خاص بالتدريب',
    no: 'لا',
    yes: 'نعم',
    rubricText: 'نص مقياس التدرج',
    rubricWeight: 'وزن مقياس التدرج',
    subQuestion: 'سؤال فرعي',
    answerLinesCount: 'عدد سطور الاجابة',
    questionTypes: {
      TF: 'صح ام خطأ',
      mcq: 'اختر من متعدد',
      mmcq: 'متعدد الخيارات',
      essay: 'مقالي',
      CS: 'دراسة حالة',
      MATCH: 'سؤال المطابقة',
    },
    timeUnits: {
      second: 'ثانية',
      minute: 'دقيقة',
      hour: 'ساعة',
    },

    language: 'اللغة',
    noSubjectIlos: 'هذا الموضوع لا يحتوي على نواتج تعلم مستهدفة !',
    noHeaderIloSelected: 'لم يتم إختيار نواتج التعلم المستهدفة !',
    reviewDateFrom: 'تاريخ طلب المراجعة من',
    reviewDateTo: 'تاريخ طلب المراجعة الى',
    createQuetionDateFrom: 'تاريخ الإنشاء من',
    createQuetionDateTo: 'تاريخ الإنشاء إلى',
    revisionQuetionDateFrom: 'تاريخ المراجعة من',
    revisionQuetionDateTo: 'تاريخ المراجعة إلى',
    createDateFrom: 'تاريخ إنشاء الإختبار من',
    createDateTo: 'تاريخ إنشاء الإختبار إلى',
    examRequestFrom: 'تاريخ طلب الإختبار من',
    examRequestTo: 'تاريخ طلب الإختبار إلى',
    fileType: 'نوع الملف',
    no_data: 'لايوجد بيانات',
    questionTimeline: 'الجدول الزمنى للسؤال',
    questionStatusChanged: 'تم تغيير حالة السؤال من ',
    questionAdded: 'تم اضافة السؤال بحالة ',
    to: 'الى',
    by: 'بواسطة ',
    date: 'بتاريخ',
    equationsInstructions: 'في حالة اضافة معادلات, هذا الرابط سينقلك الى دليل الاستخدام,',
    latexInstructions: `
      بامكانك استخدام اللاتكس في محرر المعادلات و سيتم معالجتها تلقائيا
      (ملحوظة: المعادلة لا يمكن ان تحتوي على 'begin' او 'end')`,
    lang: {
      ar: 'العربية',
      en: 'الانجليزية',
    },
    coursesStatus: 'حالة المقررات',
    tablesDist: 'توزيع الجداول',
    fromDate: 'من تاريخ',
    toDate: 'الى تاريخ',
    answersCount: 'عدد الإجابات',
    examAction: {
      deactivatedSubjectwarning: 'تم الغاء تفعيل الموضوع المخصص لهذا السؤال',
    },
    questions: 'الاسئلة',
    degrees: 'الدرجات',
    totalQuestions: 'مجموع الاسئلة',
    totalDegrees: 'مجموع الدرجات',
    reset: 'إعادة',
    ILOS_OPTIONS: 'نوع نواتج التعلم',
    OK_BTN: 'موافق',
    RESET_BTN: 'إعادة تعيين',
    CUSTOM_ILOS: 'نواتج تعلم مرنة',
    FIXED_ILOS: 'نواتج تعلم ثابتة',
    DELETEDISABLED: 'لا يمكن الحذف لوجود موضوعات مرتبطة به',
    RESET_DISABLED: 'لايمكن إعادة التعيين لوجود موضوعات مرتبطة به',
    round: 'تقريب تلقائى',
    COUNT_OF_QUESTIONS: 'عدد الاسئلة',
    COUNT_OF_ANSWERS_OPTIONS: 'عدد خانات الاجابة',
    TOTAL_CENTER_COUNT: 'إجمالي سعة المركز',
    LIMITED_CENTER_COUNT: 'سعة اللجان المحدودة',
    UNLIMITED_CENTER_COUNT: 'سعة اللجان الغير المحدودة',
    Answers: 'إجابات',

    Manual: 'يدوي',
    autoGenerated: 'تلقائي',
    electronicCorrection: 'تصحيح إلكتروني',
    electronicExam: 'إختبار إلكتروني',
    chooseExamType: 'حدد نوع إنشاء النموذج',
    choosePublishType: 'حدد طريقة تصدير الاختبار ',
    publishWarning: 'تحذير',
    degreeFrom: 'الدرجة من',
    degreeTo: 'الدرجة الى',
    timeFrom: 'الوقت من',
    timeTo: 'الوقت الى',

    degreesDistribution: 'توزيع الدرجات',
    time: 'الزمن',
    difficulty: 'الصعوبة',
    similarityRatio: 'نسبة التشابه',
    modelNo: 'نموذج رقم',
    QUESTION_MARK: 'درجة السؤال',
    QUESTION_TIME: 'وقت السؤال',
    invalidMatch: 'الاسئلة المستخدمة يجب ان تطابق بيانات الاسئلة المحددة من قبل على الاختبار',
    SHOW_EXAM_DETAILS: 'اضغط لعرض تفاصيل الاختبار',
    EXAM_DETAILS: 'تفاصيل الاختبار',
    SKIP_OPTION: 'اختيار التنقل',
    AVAILABLE_TO_DATE: 'متاح إلى',
    GIVE_FULL_MARK_HINT: 'تم إعطاء الدرجة لجميع الطلاب أو درجة السؤال أصبحت بصفر في هذا الاختبار',
    easyQuestions: 'الاسئله السهلة',
    medQuestions: 'الاسئله المتوسطة',
    hardQuestions: 'الاسئله الصعبة',
    totalDegree: 'الدرجة الكلية',
    totalTime: 'الزمن الكلي',
    degreeError: 'الرجاء التأكد من إدخال الدرجة بشكل صحيح',
    ARRANGE_PRIORITIES: 'ترتيب أولويات المراكز',
    UPGRADED_VERSION: 'تم ترقية السؤال من الإصدار %{from} إلى %{to}',
    VERSION: 'الإصدار',
    DRAW_ANSWERS_LINES: 'رسم سطور الإجابة',
    USE_QUESTIONS_NEED_UPDATE: 'إستخدام أسئلة تحتاج إلى تحديث',
    EXPOSURE_LIMIT: 'عدد مرات الظهور في الإختبارات أقل من',
    EXAMS_CREATORS: 'واضعي الاختبارات',
  },

  buttons: {
    next: 'السؤال التالي',
    previous: 'السؤال السابق',
    importStudent: 'سحب عدد الطﻻب',
    viewCourse: 'عرض تفاصيل المقرر',
    copyExam: 'نسخ إعدادات الاختبار',
    add: 'اضافة',
    addQuestion: 'اضافة سؤال جديد',
    publish: 'تصدير',
    distribute: 'توزيع القطاعات',
    isDistributed: 'تم توزيع القطاعات',
    scope: 'المجال',
    reset: 'تفريغ الخانات',
    addExam: 'إضافة اختبار',
    search: 'بحث',
    back: 'عودة',
    inputFile: 'ادخل ملف',
    ok: 'حسنا',
    cancel: 'الغاء',
    close: 'اغلاق',
    save: 'حفظ',
    confirm: 'تأكيد',
    import: 'سحب',
    export: 'تصدير',
    copy: 'نسخ',
    delete: 'حذف',
    preview: 'استعراض',
    exportQuestions: 'تصدير الأسئلة',
    edit: 'تعديل',
    simulate: 'محاكاة',
    askReview: 'طلب المراجعة',
    addRubric: 'اضافة مقياس التدرج',
    removeRubric: 'ازالة مقياس التدرج',
    addAnswer: 'اضافة اجابة',
    removeAnswer: 'ازالة اجابة',
    reject: 'رفض',
    approve: 'اعتماد',
    importIlos: 'سحب نواتج تعلم',
    resetIlos: 'حذف ربط التوصيفات',
    templateFile: 'ملف نماذج الاسئلة',
    open: 'فتح',
    show: 'عرض',
    print: 'طباعة',
    previewCourse: 'استعراض المقرر',
    examAction: {
      addSpec: 'إضافة سؤال رئيسي',

      deleteQuestion: 'حذف السؤال',
      replaceQuestion: 'الاستبدال بسؤال مشابه',
      addQuestion: 'إضافة سؤال',
    },

    reservationActions: {
      add: 'إضافة حجز',
    },
    addModel: 'إضافة نموذج',
    showExamBlueprint: 'اظهار مخطط الاختبار',
    applyDistribution: 'تطبيق التوزيع',
    equalDistribution: 'التوزيع بالتساوي',
    yes: 'نعم',
    no: 'لا',
    more: 'المزيد',
    copyAndShuffle: 'نسخ و خلط',
    onlyCopy: 'نسخ فقط',
    addSubQuestion: 'إضافة سؤال فرعي',
    uploadQuestions: 'رفع الأسئلة',
    exportNotValidQuestions: 'تصدير الأسئلة الغير صالحة',
  },

  validations: {
    codePattern: 'يجب أن يحتوي الكود على رقمين من 00 إلى 99',
    periodPattern: 'يجب أن تكون الفترة رقم صحيح من 1 إلى 9',
    required: 'هذا الحقل مطلوب',
    maxLength: 'هذا الحقل يجب ان يحتوي على %{max} حرف كحد اقصى',
    minLength: 'هذا الحقل يجب ان يحتوي على %{min} حرف على الاقل',
    maxValue: 'يجب ان لا تزيد القيمه عن %{max}',
    minValue: 'يجب ان لا تقل القيمه عن %{min}',
    invalidFileType: 'نوع الملف المدخل غير صحيح',
    invalidFileSize: 'حجم الملف يجب ان لا يزيد عن %{max} MB',
    integerValue: 'هذا الحقل يجب ان يحتوي على رقم صحيح',
    REQUIRED_FIELD: 'يجب إدخال حقل %{fieldName}',
    NOT_ALLOWED_QUESTION_TYPE: 'لا يمكنك إضافة أسئلة من هذا النوع ، حيث لا توجد أسئلة نشطة',
    MAX_NUMBER_QUESTIONS: 'أقصى عدد من الأسئلة هو %{max} سؤال',
    NOT_EQUAL_ZERO: 'لا يجب أن يساوي الرقم صفر',
    NEGATIVE_NUMBER: 'يجب أن يكون الرقم موجب',
    NO_QUESTIONS: 'لا توجد أسئلة على هذا المقرر',
    NO_FRACTION: 'لا يجب أن يحتوى الرقم على كسور',
    invalidModelTotalDegrees: 'عدد الدرجات التي تم ادخالها لا يساوي العدد الكلي لدرجات الاختبار',
    maxFloating: 'يجب ان ﻻ يزيد عدد الكسور عن رقمين',
    CHOOSE_ONE_AT_LEAST_VALIDATION: 'يجب إختيار سؤال واحد على الأقل لكي تتمكن من الرفع',
    CHOOSE_ONE_ILOS_AT_LEAST_VALIDATION: 'يجب إختيار ناتج تعلم واحد على الأقل لكي تتمكن من الرفع',
  },

  messages: {
    reservationDate: 'يجب أن يكون وقت البداية أكبر من الوقت الحالي ',
    reservationDateDiff: 'يجب أن يكون وقت النهاية أكبر من وقت البداية ',
    sureImport: 'هل انت متأكد من سحب هذه البيانات ؟',
    sureDelete: 'هل انت متأكد من حذف هذا البيان ؟',
    SURE_DELETE_ILO: 'سيتم حذف جميع نواتج التعلم الفرعية، هل أنت متأكد من حذف هذا البيان؟',
    sureCopy: 'هل انت متاكد من نسخ إعدادات الاختبار؟',
    copyWithModels: 'نسخ النماذج',
    sureSave: 'هل انت متأكد من حفظ هذا البيان ؟',

    sureBack: 'هل انت متأكد؟ سيتم الغاء التغييرات على هذا البيان !',
    sureRejectQuestion: 'هل انت متأكد من رفض هذا السؤال ؟',
    sureApproveQuestion: 'هل انت متأكد من اعتماد هذا السؤال ؟',
    sureOpen: `
      هل انت متأكد من فتح هذا السؤال ؟
      سوف يتم تحويل حالة السؤال الى مكتمل و بالتالى لن تتمكن من استخدامه فى انشاء الاختبارات
    `,
    noSimilarQuestions: 'لا توجد اسئلة مشابهة',
    modelSimilarity: 'اعلى نسبة تطابق لهذا النموذج هي',
    sureSaveModel: 'هل انت متأكد من حفظ هذا النموذج ؟',
    noMatrixYet: 'لم يتم ادخال مصفوفة للاختبار بعد',
    noModels: 'لا يوجد نماذج',
    noQuestions: 'لا توجد أسئلة',
    copyAndShuffleMode: 'هل تريد نسخ النموذج و عمل خلط بناء على اعدادات الاختبار أم نسخ فقط ؟',
    sureGoStepBack: 'لن يتم حفظ التعديلات على هذه المرحلة هل أنت متأكد ؟',
    sureDeleteSubQuestionsILOS: 'سيتم حذف نواتج التعلم على جميع الأسئله الفرعيه ، هل أنت متأكد ؟',
    noSubQuestions: 'لا يوجد أسئله فرعيه ، لن تتمكن من الحفظ ',
    selectQuestionsFirst: 'يجب إختيار أسئلة أولاً لكي تتمكن من الحذف',
    sureDeleteSelected: 'هل أنت متأكد من حذف %{num} سؤال',
    sureSaveSelected: 'هل أنت متأكد من حفظ %{num} سؤال',
    CONFLICT_BOOKED_TIME_SLOTS: 'المراكز التاليه يوجد عليها فترات غير متاحة للحجز تتقاطع مع فترة حجزك',
    addAprovalQuesions: 'الاسئلة التى سيتم اضافتها تكون حالتها تم الاعتماد',
    cannotDeleteCourse: 'لا يمكن حذف المقرر',
    cannotDeleteCourseExtraInfo: 'لا يمكن حذف المقرر الذي يحتوي على موضوعات, نواتج تعلم, أسئلة أو اختبارات',
  },

  RowsPerPage: 'عنصر لكل صفحة',
  ALL: 'الكل',
  Rights: 'كل الحقوق محفوظة',
  CITC: 'مركز تقنية الاتصالات والمعلومات',
  Mans: 'جامعة المنصورة',
  LoggedInSuccessfully: 'تم تسجيل الدخول بنجاح',
  notifications: 'الاشعارات',
  email: 'البريد',
  changeLng: 'تغيير اللغة',
  QuestionBank: 'بنك الاسئلة',
  Of: 'من',
  QuestionType: 'نوع السؤال',
  CaseStudy: 'دراسة حالة',
  QuestionText: 'نص السؤال',
  QuestionTitle: 'عنوان السؤال',
  QuestionLang: 'لغة السؤال',
  ForTraning: 'خاص بالتدريب',
  NextStep: 'الخطوة التاليه ',
  selectSubject: 'اختر موضوع',
  selectLang: 'اختر لغة',
  richTextMaxLength: 'يبغي الا تزيد عدد حروف هذا الحقل عن {max}',
  richTextLargeFile: 'تم إلغاء تحميل الملف لأنه سيتجاوز الحد الأقصى للسعة وهو 3 ميجابايت',
  questionBasicData: 'بياناات السؤال',
  subQuestions: 'الاسئلة الفرعية',
  perviousStep: 'الخطوة السابقة',
  minutes: 'الدقائق',
  houres: 'الساعات',
  seconds: 'الثواني',
  difficultyDegree: 'درجة الصعوبة',
  degree: 'الدرجة',
  answer: 'الاجابة',
  questionText: 'نص السؤال',
  answerText: 'نص الاجابه',
  answerDuration: 'مدة الاجابة',
  subQuestion: 'سؤال فرعي',
  questionType: 'نوع السؤال',
  easy: 'سهل',
  onRequiredRatio: 'مساوي للنسبة',
  underRequiredRatio: 'اقل من النسبة',
  overRequiredRatio: 'تخطى النسبة ',

  meduim: 'متوسط الصعوبة',
  difficult: 'صعب',
  correctAnswer: 'الاجابة الصحيحة',
  courseDetails: 'بيانات المقرر',
  subjectStatus: 'حالات الموضوعات',
  assessment: 'التقييمات',
  ilos: 'نواتج التعلم المستهدفة',
  generatedExams: 'الاختبارات المنشأة',
  electronicExams: 'الاختبارات الالكترونية',
  courseId: 'رقم المقرر',
  courseName: 'اسم المقرر',
  planName: 'اسم اللائحة',

  courseType: 'نوع المقرر',
  courseCode: 'كود المقرر',
  containsIlos: 'يحتوى على نواتج تعلم مستهدفة',
  yes: 'نعم',
  no: 'لا',
  containsExams: 'يحتوى على امتحانات',
  activate: 'تفعيل',
  deactivate: 'الغاء تفعيل',
  subjectsDeactivate: 'سوف يتم الغاء تفعيل الموضوعات الموجوده على هذا المقرر , هل انت متأكد؟',
  subjectsActivate:
    'من فضلك اختر الموضوعات المراد تفعيلها ثم الضغط على تأكيد او الغاء كى تظل غير مفعلة',
  courseDeactivateConfirm: 'هل تريد الغاء تفعيل هذا المقرر؟',
  courseActivateConfirm: 'هل تريد تفعيل هذا المقرر ؟',
  deleteCourseConfirm: 'هل تريد حذف هذا المقرر؟',
  courseEditDisabled: 'لا يمكن تفعيل موضوع على مقرر غير مفعل',
  EXPORT_QUESTIONS_COUNT: '  سيتم تصدير الاسئلة طبقا لمعايير البحث وعددها %{count} ',
  loginInfo: {
    User: 'مستخدم نشط',
    sysUsers: 'مستخدمى النظام',
    question: 'سؤال',
    unversity: 'كليه ومعهد',
    exam: 'امتحان',
    dataPrivacy: 'سياسة خصوصيات و امن البيانات',
    services: 'خدماتنا',
    coursesManagement: 'ادارة المقررات',
    coursesManagementDetails1:
      'تعريف وإدارة مجموعة من المقررات المتاحة داخل المؤسسة ، عرض مجموعة من الاحصائيات التي تعرض الحالة الحالية للمقرر ، والموضوعات والأسئلة والأهداف والاختبارات المرتبطة بالمقرر  ، كما نقدم تكامل API كامل مع أنظمة ابن الهيثم لـ',
    coursesManagementDetails2: ' من اجل سحب المقررات و التقييمات و الطلاب .',
    creditHours: 'الساعات المعتمدة',
    academicYear: 'العام الدراسى',
    or: 'او',
    subjectsManagement: 'ادارة الموضوعات',
    subjectsManagementDetails:
      'تعريف وإدارة الموضوعات  للمقررات المختلفة ، وتحديد نواتج التعلم المستهدفة لكل موضوع من نواتج التعلم التي تمت إضافتها مع المقرر ، تعريف عدد المحاضرات الخاص بالموضوع الذى يمكن استخدامه في بناء مخطط الاختبار, و بعد ذلك يتم استخدام الموضوعات لإنشاء أسئلة واختبارات.',
    questionCreation: 'اضافة سؤال',
    questionCreationDetails:
      'إنشاء وإدارة الأسئلة بأنواع     (اختيار من متعدد ، متعدد الخيارات ، صح أم خطأ ، مقالى ، دراسة حالة) ، يدعم النظام المعادلات الرياضية و الكيميائية ، يمكن للمستخدمين تصدير الأسئلة بصيغة xml و سحبها على مواضيع مختلفة ، سحب الأسئلة من moodle بصيغة xml ، و ارسال الاسئلة ليراجعها المراجعون.',
    questionReviewDetails:
      'يقوم مراجعو الأسئلة بمراجعة الأسئلة من وجهات نظر مختلفة بما في ذلك المراجعة اللغوية والعلمية والتحقق من أن تفاصيل الأسئلة صحيحة ، ثم يتم الموافقة على الأسئلة أو رفضها وإرسال النتائج إلى منشئ الأسئلة ، وبعد الموافقة على الأسئلة يمكن استخدامها في إنشاء الاختبارات.',
    questionReview: 'مراجعة سؤال',
    questionHistory: 'تاريخ سؤال',
    questionHistoryDetails:
      'يقوم تاريخ السؤال بإنشاء خط زمنى للتغييرات التي تتم على السؤال ، يتم تسجيل أي تغيير في تفاصيل السؤال ، و نسخ الإصدار القديم من السؤال ونقله إلى سجل جديد - إصدار جديد - وسيكون السؤال المحدث هو أحدث إصدار. يمكن للمستخدم الوصول إلى أي إصدار قديم من الخط الزمنى للسؤال.',
    examDeliveryDetails:
      'يمكن تقديم الاختبار للطلاب كاختبار ورقى  يتم تصحيحه بعد ذلك بواسطة تطبيق تصحيح الاختبارات باستخدام معالجة الصور ويتم تصدير الإجابات مرة أخرى إلى تطبيق الويب ، أو كاختبار عبر الإنترنت يمكن للطلاب الوصول إليه والإجابة على الاختبار وتصحيح الاختبار تلقائيا .',
    examDelivery: 'نشر الامتحان',
    policy1: 'جميع البيانات التي يتم تسجيلها على النظام هي ملك للجهة صاحبة هذه البيانات .',
    policy2:
      'لا يتم الاطلاع على هذه البيانات من قبل مهندسي المركز إلا للضرورة القصوى ولغرض الدعم الفني وبالاتفاق مع الجهة صاحبة البيانات شفوياً أو كتابيا .',
    policy3:
      'لا يتم إعطاء أي بيان من بيانات النظام لأي فرد او جهة فرعية تنتمى للجهة صاحبة البيانات او لأي جهة أخرى إلا بموافقة كتابية من الجهة صاحبة البيانات . ',
    policy4:
      'يمكن استخدام البيانات المسجلة على النظام بهدف عمل إحصائيات يستفاد منها فى قياس اداء النظام وكفاءة استخدامه من قبل مستخدميه .',
    policy5:
      'يتم تأمين جميع البيانات الخاصة بالنظام من خلال تأمين الحاسب الخادم الذي يستضيف البيانات ومن خلال تأمين انتقال البيانات بين جهاز المستخدم والحاسب الخادم وكذلك تأمين واجهة النظام التي يستخدمها المستخدم .',
    policy6:
      'كل مستخدم للنظام مسئول مسئولية كاملة عن كلمة المرور الخاصة به وعليه اتخاذ كافة الاحتياطات الممكنة حتى لا يستطيع أى شخص آخر الحصول عليها .',
    StructureDefinition: 'تعريف الهيكل',
    structionDescription:
      'تعريف وتنظيم الهيكل الداخلى للنظام التعليمى بالمؤسسه سواء كانت المؤسسة كلية او معهد  وما يرتبط بها من  مقررات و موضوعات و مهارات تعلم مستهدفة .',
    BankCreation: 'إنشاء البنك',
    BankCreationDescription:
      'إنشاء وتنظيم أسئلة بانواع مختلفة (مقالى - صح ام خطأ - اختيار من متعدد - متعدد الخيارات - دراسة حالة) ومراجعتها ومن ثم اعتمادها اسئله صالحه للدخول فى الامتحانات او لا.',
    ExamsCreation: 'إنشاء الامتحانات',
    ExamsCreationDescription:
      'إنشاء الاختبارات المختلفه باستخدام الاسئله المعتمده من البنك وتعريف واضافه الطلاب على الاختبارات ومن ثم تقديم الاختبار للطلاب من خلال الاختبارات الورقيه او الالكترونية .',
    ReportExtractions: 'استخراج التقارير',
    ReportExtractionsDescription:
      'استخراج والاطلاع على العديد من التقارير المختلفه التى تدور حول البيانات الاساسيه داخل المؤسسه ومنها المقررار و نواتج التعلم والامتحانات والاجابات والطلاب الكليات .',
  },
  '01.': '.01',
  '02.': '.02',
  '03.': '.03',
  '04.': '.04',
  importFaildQuestion: 'الأسئله التى لم يتم إضافتها',
  importSuccesdQuestion: 'الأسئله التى تم إضافتها بنجاح',
  selectFile: 'اختر ملف',
  BackHome: 'العودة للرئيسية',
  Pagenotfound: 'لم يتم العثور على الصفحة',

  userAddMasterRule: 'سوف يتم اضافة المستخدمين على الدور الرئيسى داخل الكليه وهو %{name}',
  importCoursesLevelRights: 'سوف يتم سحب الصلاحيات المتعلقة بالمقررات الخاصة بالمستخدم فقط',
  importSuccesdInstructors: 'اعضاء هيئة التدريس تم اضافتهم بنجاح',
  importFaildInstructors: 'لم يتم اضافتهم',
  no_master_rule:
    'برجاء التواصل مع مشرفين النظام داخل الكليه لتحديد دور رئيسى يحدد صلاحيات المستخدمين وقت السحب',
  no_ilos: 'لا يوجد نواتج تعلم مستهدفه',
  bsheetAlert: 'للدخول لنظام التصحيح الالكترونى برجاء الضغط ',
  here: 'هنا',
  iloLinkToCourse: 'اضغط هنا للانتقال لصفحه المقرر',
  resetIlosAlert1:
    'نحذير !! سيتم حذف ربط التوصيفات بالاسئله وتغير حالة كل اسئلة المقرر بما فيها الاسئله المعتمده لاسئله غير مكتمله هل انت متأكد',
  resetIlosAlert2: 'سيتم حذف التوصيفات من على كل المواضيع على المقرر هل انت متاكد',
  rePublishAlert: 'تحذير !! تم نشر هذا الاختبار من قبل ',
  NoQuestionsError: 'لا يمكن اضافة امتحان لعدم وجود أسئلة على المقرر ',
  AddIloRights: 'لا تملك صلاحيه اضافه ناتج تعلم ',
  OnlyExternalCourse: 'لا يوجد نواتج تعلم على المقرر قم بسحب نواتج التعلم من صفحة المقررات',
  CountIloValidation: 'يجب ان يحتوي المقرر علي الاقل علي  ناتج تعلم واحد',
  arName: 'الأسم باللغه العربيه',
  enName: 'الأسم باللغه الانجليزيه',
  foundation: 'جهة العمل',
  jobEndDate: 'تاريخ انتهاء العمل',
  nationalId: 'الرقم القومى',
  changePassword: 'تغيير كلمة المرور',
  userName: 'اسم المستخدم',
  currentPassword: 'كلمة المرور الحالية',
  newPassword: 'كلمة المرور الجديدة',
  confirmPassword: 'تاكيد كلمة المرور',
  WEAK_PASSWORD:
    ' كلمة مرور ضعيفة : يجب أن تحتوى على الأقل على ثمانية حروف منهم حروف إنجليزية صغيرة وحروف إنجليزية كبيرة وأرقام ورموز',
  NOT_CONFIRMED: 'كلمات المرور غير متطابقة',
  profile: 'الملف الشخصي',
  EXAM_DATA: 'بيانات الاختبار ',
  EXAM_TITLE: 'عنوان الاختبار',
  DEGREE: 'الدرجه',
  EXAM_TYPE: 'نوع الاختبار',
  ACADIMIC_YEAR: 'العام الأكاديمي',
  notInserted: 'غير مسجل',
  Email: 'البريد الإلكتروني',
  TERM: 'الفصل الدراسي',
  SHUFFLE_OPTIONS: 'اختيارات الخلط',
  TIME_IN_MINUTES: 'الوقت (دقيقة)',
  QUESTION_LANGUAGE_F: 'اللغة',
  HAS_ADD_DEGREES: 'له درجات اضافيه',
  TRAINING_QUESTIONS: 'اسئله التدريب',
  EXPOSURE_LIMIT: 'عدد مرات الظهور في الاختبارات',
  MODELS_MATCH: 'تطابق النماذج',
  COURSE_INSTRUCTOR: 'أستاذ المادة',
  COURSE_CODE: 'كود المقرر',
  CREATED_BY: 'انشأ بواسطة',
  REVIEWED_BY: 'تم مراجعته بواسطة',
  EXAM_DIFFICULTY: 'صعوبة الامتحان',
  SIGNATURE: 'توقيع المدرس',
  EASY: 'سهل',
  MEDIUM: 'متوسط',
  HARD: 'صعب',
  MODEL_NUMBER: 'رقم النموذج',

  Note: 'ملاحظة',
  coursesTip: 'عدد المقررات التى تمتلك صلاحية لها فى تطبيبق المقررات',
  questionsTip: 'الأسئلة التى تم إنشائها بواسطة',
  examsTip: 'الإختبارات التى تم إنشائها بواسطة',
  subjectsTip: 'عدد الموضوعات المرتبطه بالمقررات التى تمتلك صلاحيه لها فى تطبيق الموضوعات',

  statusReportCourseStatus: {
    allCourses: 'كل المقررات',
    hasQuestions: 'مقررات تحتوي على اسئلة',
    hasNoQuestions: 'مقررات لا تحتوي على اسئلة',
  },

  statusReportDistributions: {
    byFaculty: 'بالكلية',
    byDepartment: 'بالقسم',
    byGrade: 'بالفرقة',
  },
  simulateQuestion: 'محاكاة السؤال',
  theAnswer: 'إجابة السؤال',
  fileReaderValidationError: 'عذرا قارئ الملفات لا يعمل',
  imgTypeValidationError: 'عذرا لا يتم رفع صورة الا بصيغة jpg أو jpeg أو png',
  imgSizeValidationError: 'عذرا الحد الأقصى للصورة هو 1 ميجابايت',
  SelectProfileImg: 'تحديد الصورة الشخصية',
  Done: 'تم',
  invalidDegreesTotal: 'عدد الدرجات التي تم ادخالها لا يساوي العدد الكلي لدرجات الاختبار',
  invalidQuestionsTotal: 'عدد الاسئلة التي تم ادخالها لا يساوي العدد الكلي لأسئلة الاختبار',
  zeroQuestion: 'عدد الأسئلة لا يمكن أن يكون 0 و العدد المقابل من الدرجات أكبر من 0',
  zeroDegree: 'عدد الدرجات لا يمكن أن يكون 0 و العدد المقابل من الأسئلة أكبر من 0',
  iloSelectedZero: 'لا يمكن اختيار ناتج تعلم بوزن صفر ',
  cellDetails: 'تفاصيل الخلية',
  noData: 'لا يوجد بيانات',
  integarInvalid: 'يرجى ادخال عدد صحيح',
  enterQuestionsFirst: 'يرجى استكمال الخطوة الثانية حتى تتمكن من نعديل المصفوفة',
  NO_STUDENTS_TO_WITHDRAW: 'لا يوجد طلاب للسحب',

  QUESTIONS_DETAILS: {
    CELL_DETAILS: 'تفاصيل المقرر',
  },

  CHARTS_HEADER: {
    SUBJSTATS: 'إحصائيه بتغطيه المواضيع',
    DIFFSTATS: 'إحصائيه بتغطية مستويات الصعوبه',
    ILOSTATS: 'إحصائيه بتغطيه نواتج التعلم المستهدفه',
    TYPESTATS: 'إحصائيه بتغطيه أنواع الأسئلة',
  },

  SUB_ILOS: 'نواتج التعلم الفرعية',
  maxILOs: 'لا يمكن اضافة اكثر من 10 نواتج تعلم ',
  minILOs: 'يجب ان يحتوي المقرر على ناتج تعلم واحد على الاقل ',
  model_percentages_chart_title: 'نسبة تحقق معايير الاختبار',
  maintenanceNotice:
    'يرجى العلم انه سيتم ايقاف النظام اليوم الخميس 17/03/2022 فى تمام الساعة الخامسة مساءا لاجراء بعض التحديثات لرفع كفائة النظام',

  deprecatedEquationFormat:
    'يحتوي النص التالي علي صيغة معادلات لم تعد مدعومة. برجاء إعادة إدخال النص.',
  IMPORT_ALL_STUDENTS_OPTION: 'كل الطلبة',
  IMPORT_FRESHMEN_OPTION: 'الطلبة المستجدين',
  IMPORT_REMAINING_STUDENTS_OPTION: 'الطلبة التخلفات',
  HOUR: 'الساعة',
  MIN: 'الدقيقة',
  AM: 'ص',
  PM: 'م',
  AMPM: 'ص/م',
  SUB_QUESTION_NUM: 'رقم السؤال الفرعي',
  NEXT_SUB_QUESTION: 'السؤال الفرعي التالي',
  PERVIOUS_SUB_QUESTION: 'السؤال الفرعي السابق',
  CHANGE_STAGE: 'تغيير المرحلة الدراسية',
  VERIFY: 'تحقق',
  CAPTCHA_KEY: 'رمز التحقق',
  NO_STAGE: 'لا يوجد مرحلة',
  SYNC_STAFF_COURSES: 'تحديث مقررات عضو هيئة تدريس',
  NO_SYNC_STAFF_COURSES_RIGHT: 'ليس لديك صلاحية لتحديث مقررات عضو هيئة تدريس',
  ENTER_VALID_EGYPTIAN_NATIONAL_ID: 'من فضلك أدخل رقم قومي صحيح',
  STAFF_NAME: 'الإسم',
  MINOR: 'تعديل طفيف، مع الإبقاء على الإصدار الحالي للسؤال',
  MAJOR: 'تعديل جوهري، مع ترقية السؤال لإصدار جديد',
  VIEW_QUESTION_REPORTS: 'عرض تقارير السؤال',
  VIEW_QUESTION_DETAILS: 'عرض تفاصيل السؤال',
  QUESTION_REPORTS: 'تقارير السؤال',
  IBN_HIATHEM_COURSE_IDS: 'رقم إبن الهيثم الخاص بمقررات لم يتم إيجادها',
  USER_OUT_SIDE_SYSTEM_ALERT: 'لا يمكن تحديث المقررات لمستخدم تم إضافته من خارج النظام',
  BOOKED_TIME_SLOTS: 'فترات غير متاحة للحجز',
  SUB_QUESTION_NEED_TO_FIX: 'أحد الأسئلة الفرعية يحتاج إلى تحديث',
  AUTO_SELECT_DATA: 'بيانات تخص الإختيار التلقائي للأسئلة',
  PDF: 'ملف PDF',
  DOCX: 'ملف DOCX',
  TIME_ZONE_ALERT: ' يجب التأكد من ضبط توقيت الجهاز الخاص بك بشكل صحيح لأنه يؤثر فى موعد الاختبار المدخل، يجب مراجعة موعد الاختبار بعد حفظ الطلب.',
  EXPORT_FOR_BSHEET: 'إمكانية التصدير إلى نظام التصحيح الإلكتروني',
  BSHEET_QUESTION_LANG_TIP: 'برجاء اختيار لغة السؤال لتفعيل هذا الاختيار',
};

export default arTranslate;
