export const QUESTIONS_ROUTES = [
  {
    path: 'courses-list',
    name: 'questions-courses-list',
    component: () => import('@/components/questions/CourseList.component.vue'),
  },
  {
    path: ':courseId/questions-list',
    name: 'questions-list',
    component: () => import('@/components/questions/questionsList.component.vue'),
  },
  {
    path: ':courseId/view/:questionType/:questionId',
    name: 'question-details',
    component: () => import('@/components/questions/questionDetails.component.vue'),
  },

  {
    path: ':courseId/addCaseStudyQuestion',
    name: 'addCaseStudy',
    component: () => import('@/components/questions/caseStudy/caseStudyAddEdit.component.vue'),
  },
  {
    path: ':courseId/copyCaseStudyQuestion/:questionId',
    name: 'copyCaseStudy',
    component: () => import('@/components/questions/caseStudy/caseStudyAddEdit.component.vue'),
  },
  {
    path: ':courseId/editCaseStudyQuestion/:questionId',
    name: 'editCaseStudy',
    component: () => import('@/components/questions/caseStudy/caseStudyAddEdit.component.vue'),
  },
  {
    path: ':courseId/:questionType/add',
    name: 'question-add',
    component: () => import('@/components/questions/questionAddEdit.component.vue'),
  },
  {
    path: ':courseId/:questionType/copy/:questionId',
    name: 'question-copy',
    component: () => import('@/components/questions/questionAddEdit.component.vue'),
  },
  {
    path: ':courseId/:questionType/edit/:questionId',
    name: 'question-edit',
    component: () => import('@/components/questions/questionAddEdit.component.vue'),
  },
  {
    path: ':courseId/import',
    name: 'questions-import',
    component: () => import('@/components/questions/QuestionImport.component.vue'),
  },

];

export const QUESTIONS_ROUTES_SCOPES_RIGHTS = {
  'questions-courses-list': '9.2.',
  'questions-list': '9.2.',
  'question-details': '9.2.',
  'question-add': '9.3.',
  'question-copy': '9.3.',
  'question-edit': '9.3.',
};
