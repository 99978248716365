

























































































































import { Component } from 'vue-property-decorator';
/* eslint-disable import/no-cycle */
import { mixins } from 'vue-class-component';
import IlosComponets from '@/shared/ilos-add-component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import SubjectProvider from '../../providers/subject.provider';
import { Subject } from '../../types/subject.types';
import Toaster from '../../mixins/toaster.mixin';
import { Lang } from '../../types/general.type';
import { SUBJECT_ROUTE_NAME, APPIDS } from '../../enums';
import { HeaderILos, FlexibleILOs } from '../../types/ilos.type';
import SelectedCourse from '../SelectedCourse.component.vue';
import { CourseBasic } from '../../types/course.type';
import SubjectFlexibleILOs from './SubjectFlexibleILOs.component.vue';

@Component({
  components: {
    'ilos-edit': IlosComponets,
    SelectedCourse,
    ScopeComponent,
    SubjectFlexibleILOs,
  },
})
export default class SubjectAddEdit extends mixins(Toaster) {
  name = 'SubjectAddEdit';

  appId: string = APPIDS.SUBJECTS;

  courseId: string = this.$route?.params.courseId;

  subjectId: string = this.$route?.params.subjectId;

  courseActive = false;

  subject: Subject = {};

  loading: boolean | string = false;

  editSubject: Subject = {
    name: '',
    lectureCount: '',
  };

  validForm = true;

  nameRules = [
    (v: string) => v.trim().length > 0 || this.$t('Required'),
    (v: string) => v.length <= 50 || this.$t('validations.maxLength', { max: 50 }),
  ];

  lectureCountRules = [
    (v: number) => !v || Number(v) >= 0 || this.$t('input_number_validation'),
    (v: number) => Number(v) <= 50 || this.$t('validations.maxValue', { max: 50 }),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  courseILOS: HeaderILos = {};

  isFlexibleILOs = false;

  flexibleILOs!: FlexibleILOs;

  updateFlexibleILOs(ilos: FlexibleILOs) {
    this.flexibleILOs = JSON.parse(JSON.stringify(ilos));
  }

  error: Lang | null = null;

  resError: Lang | null = null;

  course: CourseBasic = {};

  get activeStatus() {
    return [
      {
        text: this.$t('Yes'),
        value: true,
      },
      {
        text: this.$t('No'),
        value: false,
      },
    ];
  }

  async mounted() {
    if (this.$route.name === SUBJECT_ROUTE_NAME.EDIT) {
      await this.getSubjectData(this.subjectId);
    } else if (this.$route.name === SUBJECT_ROUTE_NAME.ADD) {
      this.creatNewSubject();
    }
  }

  async getSubjectData(subjectId: string) {
    try {
      this.showLoading();
      const { subject, ilos } = await SubjectProvider.getSubjectInfo(
        subjectId,
        this.courseId,
      );
      this.subject = subject;
      if (subject.isFlexibleILOs) {
        this.flexibleILOs = ilos as FlexibleILOs;
        this.isFlexibleILOs = !!subject.isFlexibleILOs;
      } else this.courseILOS = ilos as HeaderILos;

      Object.assign(this.editSubject, this.subject);
      this.hideLoading();
    } catch (err) {
      this.hideLoading();
      this.error = err;
    }
  }

  async creatNewSubject() {
    this.subject = this.editSubject;
    this.editSubject.active = true;
    this.editSubject.ilos = {
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    };
    try {
      this.showLoading();
      const { ilos, isFlexibleILOs } = await SubjectProvider.getCourseIlos(
        this.appId,
        this.courseId,
      );
      if (isFlexibleILOs) {
        this.flexibleILOs = ilos as FlexibleILOs;
        this.isFlexibleILOs = isFlexibleILOs;
      } else this.courseILOS = ilos as HeaderILos;
      this.hideLoading();
    } catch (err) {
      this.hideLoading();
      this.error = err;
    }
  }

  showLoading() {
    this.loading = 'secondary';
  }

  hideLoading() {
    this.loading = false;
  }

  async onSaveClick() {
    this.resError = null;
    if (!this.$refs.form.validate()) {
      return;
    }
    if (this.$route.name === SUBJECT_ROUTE_NAME.EDIT) {
      await this.updateSubject(this.editSubject);
    } else if (this.$route.name === SUBJECT_ROUTE_NAME.ADD) {
      await this.addSubject(this.editSubject);
    }
  }

  async updateSubject(subject: Subject) {
    try {
      const subjectObj: Subject = { ...subject };
      if (this.isFlexibleILOs) {
        subjectObj.ilos = this.flexibleILOs;
      }
      await SubjectProvider.updateSubject(subjectObj, this.courseId, this.isFlexibleILOs);
      this.$router.go(-1);
    } catch (err) {
      this.hideLoading();
      this.resError = err;
    }
  }

  async addSubject(subject: Subject) {
    try {
      const subjectObj: Subject = { ...subject };
      if (this.isFlexibleILOs) {
        subjectObj.ilos = this.flexibleILOs;
      }
      const subjectId = await SubjectProvider.addSubject(
        subjectObj,
        this.courseId,
        this.isFlexibleILOs,
      );
      this.$router.replace({
        name: 'subject-list',
        params: { courseId: this.courseId },
        hash: `#${subjectId}`,
      });
    } catch (err) {
      this.hideLoading();
      this.resError = err;
    }
  }

  onBackClick() {
    this.$router.go(-1);
  }

  setCourse(course: CourseBasic) {
    this.course = course;
    this.courseActive = Boolean(this.course.active);
  }
}
