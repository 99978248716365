import Vue from 'vue';
import servertranslate from './server-translate';
import trim from './trim.filter';
import truncate from './truncate.filter';
import formatDate from './formatDate.filter';

Vue.filter('servertranslate', servertranslate);
Vue.filter('trim', trim);
Vue.filter('truncate', truncate);
Vue.filter('formatDate', formatDate);
