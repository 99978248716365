










/* eslint-disable import/no-cycle */
import { Component, Vue, Provide } from 'vue-property-decorator';
import { APPIDS, SUBJECT_ROUTE_NAME } from '@/enums/';
import SubjectProvider from '@/providers/subject.provider';
import CourseList from '../../shared/components/CourseList.component.vue';

@Component({
  components: { CourseList },
})
export default class SubjectCourseList extends Vue {
  @Provide() Provider = SubjectProvider;

  appId = APPIDS.SUBJECTS;

  name = 'SubjectCourseList';

  openCourseSubjectList(data) {
    this.$router.push({
      name: SUBJECT_ROUTE_NAME.LIST,
      params: {
        courseId: data.courseId,
      },
    });
  }
}
