export const REVIEW_QUESTIONS_ROUTES = [
  {
    path: 'courses-list',
    name: 'review-questions-courses-list',
    component: () => import('@/components/review-questions/coursesList.component.vue'),
  },
  {
    path: ':courseId/questions-list',
    name: 'review-questions-list',
    component: () => import('@/components/review-questions/QuestionList.component.vue'),
  },
  {
    path: ':courseId/review/:questionType/:questionId',
    name: 'question-review',
    component: () => import('@/components/review-questions/questionReview.component.vue'),
  },

];

export const REVIEW_QUESTIONS_ROUTES_SCOPES_RIGHTS = {
  'review-questions-courses-list': '9.2.',
  'question-review': '9.2.',
};
