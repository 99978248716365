import { COURSES_ILOS_ROUTE_NAME } from '@/enums';

export const COURSE_ILOS_ROUTES = [
  {
    path: COURSES_ILOS_ROUTE_NAME.COURSES_LIST,
    name: COURSES_ILOS_ROUTE_NAME.COURSES_LIST,
    component: () => import('@/components/add-ilos/CourseList.component.vue'),
  },
  {
    path: ':courseId/course-ilos',
    name: COURSES_ILOS_ROUTE_NAME.COURSES_ILOS_VIEW,
    component: () => import('@/components/add-ilos/viewIlos.component.vue'),
  },

];

export const ADD_ILOS_ROUTES_SCOPES_RIGHTS = {
  'course-ilos-course-list': '9.2.',
  'course-ilos-view': '9.2.',
};
