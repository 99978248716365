import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './auth.store';
import lang from './lang.store';
import app from './app.store';
import scope from './scope.store';
import filters from './filters.store';
import course from './course.store';
import navigatQuestionsListId from './navigatQuestionsListId.store';

Vue.use(Vuex);
/* eslint-disable @typescript-eslint/no-explicit-any */
const vuexLocal = new VuexPersistence<any>({
  storage: window.localStorage,
  modules: ['auth', 'lang', 'app', 'scope', 'course', 'filters', 'navigatQuestionsListId'],
});

export default new Vuex.Store({
  state: {
    drawerState: false,
  },
  mutations: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    logout(state: any) {
      state.auth.token = null;
      state.auth.username = null;
      state.auth.resetPassword = false;
      state.auth.resetMessage = null;
      state.auth.sessionDuration = null;
      state.auth.sessionExpiry = null;
      state.app.activeSubSystem = null;
      state.app.activeApp = null;
      state.app.displayApp = true;
      state.app.mini = false;
      state.app.userStagesData = [];
      state.app.activeStageId = null;
      state.app.isTechnicalSupport = null;

      state.course.selectedCourseId = null;
      state.course.selectedCourseName = null;

      state.filters.appFilters = {};
      state.filters.courseFilters = {};
    },

    openDrawer(state: any) {
      state.drawerState = true;
    },
    closeDrawer(state: any) {
      state.drawerState = false;
    },
    clearNavQuestionList(state: any) {
      state.navigatQuestionsListId.questionsIdLists = {};
    },
  },
  actions: {
  },
  modules: {
    auth, lang, app, scope, filters, course, navigatQuestionsListId,
  },
  plugins: [vuexLocal.plugin],

});
