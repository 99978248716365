
























































import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { Subject } from '@/types/subject.types';
import coursesProvider from '@/providers/courses.provider';
import Toaster from '@/mixins/toaster.mixin';
import { APPIDS } from '@/enums/';
import DialogComponent from '@/components/dialog.component.vue';

@Component({
  components: {
    DialogComponent,
  },
})
export default class CourseActivationDialog extends mixins(Toaster) {
  appId = APPIDS.COURSES;

  @Prop() subjectsToBeActivated!: boolean;

  @Prop() active!: boolean;

  @Prop() courseId!: string;

  subjects: Subject[] = [];

  selectedSubjects: string[] = [];

  showConfirmationDialog = false;

  checkActiveSubjects() {
    return !this.active && this.subjects.length;
  }

  selectAll(checked) {
    this.selectedSubjects = checked ? this.subjects.map((c) => c.id ?? '') : [];
  }

  checkSelected() {
    return !this.active && this.selectedSubjects.length === 0;
  }

  getValue(subject) {
    return this.selectedSubjects.includes(subject.id);
  }

  async mounted() {
    this.loadSubjects();
  }

  async loadSubjects() {
    const result = await coursesProvider.getCourseSubjects(
      this.appId,
      this.courseId,
    );
    this.subjects = result.subjects;
    if (this.subjects.length === 0) {
      this.activateCourse();
    } else {
      this.showConfirmationDialog = true;
    }
  }

  async activateCourse(activateSubjects = true) {
    try {
      const selected = this.selectedSubjects?.length === this.subjects?.length || this.active
        ? []
        : this.selectedSubjects;
      const result = await coursesProvider.courseActivation(
        this.appId,
        this.courseId,
        !this.active,
        selected,
        activateSubjects,
      );
      this.successToaster(result.message);
      this.$emit('activationDone');
    } catch (err) {
      this.errorToaster(err);
    }
  }

  close() {
    this.$emit('close');
  }
}
