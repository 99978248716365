












































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DialogComponent extends Vue {
  @Prop(Boolean) readonly value!: boolean;

  @Prop({ default: false }) readonly persistent!: boolean;

  @Prop({ default: '600px' }) readonly width!: boolean;

  @Prop({ default: false }) readonly fullscreen!: boolean;

  @Prop({ default: ' dialog-transition' }) readonly transition!: boolean;

  @Prop({ default: 'blue-grey' }) readonly color !: string;

  @Prop({ default: false }) readonly preventClose!: boolean;

  get showDialog() {
    return this.value;
  }

  set showDialog(value) {
    this.$emit('input', value);
  }

  closeDialog() {
    this.$emit('close');
    if (!this.preventClose) {
      this.$emit('input', false);
    }
  }
}
