






















import { Vue, Prop, Component } from 'vue-property-decorator';
import { FlexibleILOsData } from '@/types/ilos.type';

@Component({
  name: 'recursive-ilos-checkbox',
})
export default class RecursiveILOsCheckbox extends Vue {
  @Prop() readonly items!: FlexibleILOsData[];

  get isRightAlignment(): boolean {
    return this.$store.state.lang.lang === 'ar';
  }

  onChangeCheckBox(props: { isSelected: boolean; item: FlexibleILOsData }) {
    this.$emit('ilo-checkbox-change', { isSelected: props.isSelected, item: props.item });
  }

  get isParentSelected() {
    return (item: FlexibleILOsData) => {
      if (item.children && Object.values(item.children).length > 0) {
        return !Object.values(item.children)
          .map((subItem) => {
            if (subItem.children && Object.values(subItem.children).length > 0) {
              return this.isParentSelected(subItem);
            }
            return subItem.selected;
          })
          .includes(false);
      }
      return item.selected;
    };
  }
}
