import { COURSES_ROUTE_NAME } from '@/enums';

export const COURSES_ROUTES = [
  {
    path: 'list',
    name: COURSES_ROUTE_NAME.LIST,
    component: () => import('@/components/courses/coursesList.component.vue'),
  },
  {
    path: 'add',
    name: COURSES_ROUTE_NAME.ADD,
    component: () => import('@/components/courses/coursesAdd.component.vue'),
  },
  {
    name: COURSES_ROUTE_NAME.INSTRUCTORS,
    path: 'instructors',
    component: () => import('@/components/courses/courseInstructors.component.vue'),
  },
  {
    name: COURSES_ROUTE_NAME.VIEW,
    path: ':courseId/',
    component: () => import('@/components/courses/coursesView.component.vue'),
  },
];

export const COURSES_ROUTES_SCOPES_RIGHTS = {
  'course-list': '9.2.',
  'course-add': '9.3.',
  'course-view': '9.2.',
  'course-instructors': '9.12.',
};
