









import { Vue, Prop, Component } from 'vue-property-decorator';
import { FlexibleILOs, FlexibleILOsData } from '@/types/ilos.type';
import RecursiveILOsCheckbox from './RecursiveILOsCheckbox.component.vue';

@Component({
  components: {
    RecursiveILOsCheckbox,
  },
})
export default class SubjectFlexibleILOs extends Vue {
  @Prop() readonly items!: FlexibleILOs;

  ilos: FlexibleILOs = {};

  mounted() {
    this.ilos = JSON.parse(JSON.stringify(this.items));
  }

  onChangeCheckBox(props: { isSelected: boolean; item: FlexibleILOsData }): void {
    this.onChangeItemChidrenRec(props.isSelected, [props.item]);
    this.$emit('update-flexible-ilos', JSON.parse(JSON.stringify(this.ilos)));
  }

  /* eslint-disable no-param-reassign */
  onChangeItemChidrenRec(isSelected: boolean, item: FlexibleILOsData[]): void {
    item.forEach((subItem) => {
      subItem.selected = isSelected;
      if (subItem.children && Object.values(subItem.children).length > 0) {
        this.onChangeItemChidrenRec(isSelected, Object.values(subItem.children));
      }
    });
  }
}
