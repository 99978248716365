import { Scope } from '@/types/general.type';

const scopeList: Scope[] = [];

const scope = {
  state: {
    selectedScopeId: '',
    selectedScopeName: '',
    isLastLevel: false,
    isDepartmentLevel: false,
    scopeList,
  },
  mutations: {
    resetScope(state) {
      state.scopeList = [];
      state.selectedScopeId = null;
      state.selectedScopeName = null;
      state.isLastLevel = false;
      state.isDepartmentLevel = false;
    },
    scopeList(state, listScope: Scope[]) {
      state.scopeList = listScope;
    },
    selectScope(state, payload) {
      if (payload.scope.name) {
        state.selectedScopeId = payload.scope.id;
        state.selectedScopeName = payload.scope.name;
      } else {
        state.selectedScopeId = null;
        state.selectedScopeName = null;
      }
    },
    setTreeLastLevel(state, isLastLevel) {
      state.isLastLevel = isLastLevel;
    },
    setTreeDepartmentLevel(state, isDepartmentLevel) {
      state.isDepartmentLevel = isDepartmentLevel;
    },

  },
};

export default scope;
