/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue';
import VueToast, { ToastApi } from 'vue-toast-notification';
import VueI18n from 'vue-i18n';
import languages from '@/i18n/index';

import App from './App.vue';
import './registerServiceWorker';
import './class-component-hooks';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './filters';
import 'vue-toast-notification/dist/theme-default.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './assets/scss/main.scss';

Vue.use(VueI18n);

Vue.use(VueToast);

const messages = languages;
Vue.config.productionTip = false;
const langState: any = store.state;
const i18n = new VueI18n({
  locale: langState.lang.lang,
  fallbackLocale: 'en',
  messages,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

declare module 'vue/types/vue' {
  interface VueConstructor {
    $toast: ToastApi;
    $router: any;
    $store: any;
    $t: any;
    t: any;
  }
}
declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface Vue {
    $toast: ToastApi;
  }
}
