import Vue from 'vue';
import Component from 'vue-class-component';
import { TranslateResult } from 'vue-i18n';
import { Lang } from '@/types/general.type';
import servertranslate from '@/filters/server-translate';

@Component
export default class Toaster extends Vue {
  servertranslate = servertranslate;

  successToaster(msg: Lang, opt?: {}) {
    this.$toast.success(this.servertranslate(msg), {
      message: this.servertranslate(msg),
      position: 'bottom-left',
      type: 'success',
      ...opt,
    });
  }

  warningToaster(msg: Lang, opt?: {}) {
    this.$toast.success(this.servertranslate(msg), {
      message: this.servertranslate(msg),
      position: 'bottom-left',
      type: 'warning',
      ...opt,
    });
  }

  errorToaster(msg: Lang | TranslateResult, opt?: {}) {
    let errormsg = '';
    if (typeof msg === 'object') {
      errormsg = this.servertranslate(msg);
    } else {
      errormsg = msg;
    }
    this.$toast.success(errormsg, {
      message: errormsg,
      position: 'bottom-left',
      type: 'error',
      ...opt,
    });
  }

  infoToaster(msg: Lang | TranslateResult, opt?: {}) {
    let errMsg = '';
    if (typeof msg === 'object') {
      errMsg = this.servertranslate(msg);
    } else {
      errMsg = msg;
    }
    this.$toast.success(errMsg, {
      message: errMsg,
      position: 'bottom-left',
      type: 'info',
      ...opt,
    });
  }

  defaultToaster(msg: Lang, opt?: {}) {
    this.$toast.success(this.servertranslate(msg), {
      message: this.servertranslate(msg),
      position: 'bottom-left',
      type: 'default',
      ...opt,
    });
  }
}
