








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  created() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.changeTheme);
  }

  changeTheme() {
    this.$vuetify.theme.dark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;
  }

  destroyed() {
    window.removeEventListener('scroll', this.changeTheme);
  }
}
