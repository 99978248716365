/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import Vue from 'vue';
import store from '../store/index';
import router from '../router';
import servertranslate from '../filters/server-translate';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

instance.interceptors.request.use((conf) => {
  const config = { ...conf };
  const autState: any = store.state;
  const { token } = autState.auth;
  if (token) {
    config.headers.authorization = `Authori${token}`;
  }
  return config;
},
(error) => error);

instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

instance.interceptors.response.use((response) => {
  store.commit('updateToken', { token: response.headers.authorization });
  return response.data;
}, (error) => {
  let message;
  if (error.response.status === 401) {
    store.commit('logout');
    if (window.location.pathname !== '/login') {
      router.push(`/login?redirect=${window.location.pathname}`).catch();
      return Promise.reject(error.response.data);
    }
  } if (error.response.status === 404 || error.response.status === 403) {
    store.commit('resetScope');
    store.commit('resetCourse');
    router.push('/notFound');
    return Promise.reject(error.response.data);
  }
  if (typeof (error.response.data) === 'string') {
    message = { ar: 'حدث خطأ يرجى ابلاغ الدعم الفنى ', en: ' An error occured please contact technical support' };
  } else {
    message = error.response.data;
  }
  Vue.$toast.error(servertranslate(message), {
    message: servertranslate(message),
    position: 'bottom-left',
    type: 'info',
    queue: true,
  });
  if (Vue.$toast) {
    Vue.$toast.clear();
  }
  return Promise.reject(message);
});

export default instance;
