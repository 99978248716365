import moment from 'moment';
import store from '../store';

const langState: { lang: { lang: string } } = store.state;
export default function formatDate(stringDate, format = 'DD MMMM YYYY, hh:mm A', currentLocale: string = langState.lang.lang) {
  const parsedMomentDate = moment(stringDate);
  if (!parsedMomentDate.isValid()) { return ''; }

  return parsedMomentDate.local(true).locale(currentLocale).format(format);
}
