import { Rights } from '@/enums';

export const RESERVE_EXAM_ROUTES = [
  {
    path: 'courses-list',
    name: 'reserve-exam-courses-list',
    component: () => import('@/components/reserve-exam/coursesList.component.vue'),
  },
  {
    path: ':courseId/reserve-exam-list',
    name: 'reserve-exam-list',
    component: () => import('@/components/reserve-exam/reserveExamsList.component.vue'),
  },
  {
    path: ':courseId/add',
    name: 'reserve-exam-add',
    component: () => import('@/components/reserve-exam/addReservation.component.vue'),
  },
  {
    path: ':courseId/view/:reserveExamId',
    name: 'reserve-exam-details',
    component: () => import('@/components/reserve-exam/viewReservation.component.vue'),

  },
];

export const RESERVE_EXAM_ROUTES_SCOPES_RIGHTS = {
  'reserve-exam-courses-list': Rights.view,
  'reserve-exam-list': Rights.view,
  'reserve-exam-details': Rights.view,
};
