import store from '../store';

const langState: { lang: { lang: string } } = store.state;
export default function servertranslate(value, key = langState.lang.lang) {
  let langObject = { ar: '', en: '' };
  if (value) {
    if (typeof value === 'string') {
      langObject = JSON.parse(value);
    } else if (typeof value === 'object' && value[key]) {
      langObject = value;
    }
  }

  return langObject[key];
}
