import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import minifyTheme from 'minify-css-string';
import '@fortawesome/fontawesome-free/css/all.css';
import store from '../store';

Vue.use(Vuetify);
/* eslint-disable @typescript-eslint/no-explicit-any */
const langStore: any = store.state;
const { lang } = langStore.lang;

export default new Vuetify({
  rtl: lang === 'ar',
  icons: {
    iconfont: 'md',
  },
  theme: {
    // dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    options: {
      minifyTheme,
      customProperties: true,
    },

    themes: {
      light: {
        primary: '#374a8a',
        secondary: '#5E3A5A',
        accent: '#e91e63',
        error: '#f44336',
        warning: '#FF5722',
        info: '#2196f3',
        success: '#3ed6a2',
        lightgray: '#f7f7f7',
        white: '#fff',
        darkgray: '#555555',
        middlegray: '#797979',
        gray: '#e6e6e6',
        black: '#000',
      },
      dark: {
        primary: '#67b5da ',
        secondary: '#e27d39 ',
        accent: '#e91e63',
        error: '#f44336',
        warning: '#FF5722',
        info: '#2196f3',
        success: '#51da81',
        lightgray: '#555555',
        white: '#000',
        darkgray: '#f7f7f7',
        middlegray: '#e6e6e6',
        gray: '#e6e6e6',
        black: '#f7f7f7',

      },
    },
  },
});
