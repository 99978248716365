/* eslint-disable @typescript-eslint/no-explicit-any */

import { Subject, SubjectListResponse } from '@/types/subject.types';
import { FlexibleILOs, HeaderILos, Ilo } from '@/types/ilos.type';
import { CourseFiltersInputs, CourseListProvider, CoursesListResponse } from '@/types/course.type';
import { APPIDS } from '@/enums';
import { Lang } from '@/types/general.type';
import provider from './main.provider';

class SubjectProvider implements CourseListProvider {
  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const courseFilters = { ...query, activeStatus: '1' };
    const result: CoursesListResponse = await provider.get(`/api/courses/${appId}/getList`, {
      params: courseFilters,
    });
    return result;
  }

  async getCourseSubjects(courseId: string, appId = APPIDS.SUBJECTS,
    activeStatus?): Promise<SubjectListResponse> {
    const subjectsResonse: any = await provider.get(`/api/subjects/${appId}`, {
      params: {
        courseId,
        activeStatus,
      },
    });

    let subjects: Array<Subject> = [];
    if (Array.isArray(subjectsResonse.subjects)) {
      subjects = subjectsResonse.subjects.map((item: any) => ({
        id: `${item.subjectID}`,
        name: item.subjectName,
        active: Boolean(item.active),
        lectureCount: Number(item.lectureCount) || 0,
        ilos: subjectsResonse.isFlexibleILOs
          ? item.ilosData
          : SubjectProvider.handleMAgicIloStructureForView(item.ilosData),
      }));
    }
    let rights: Array<string> = [];
    if (Array.isArray(subjectsResonse.rights)) {
      rights = subjectsResonse.rights;
    }
    const result = {
      subjects,
      rights,
      isFlexibleILOs: subjectsResonse.isFlexibleILOs,
    };
    return result;
  }

  async getSubjectInfo(SubhjectId: string, courseId: string):
    Promise<{ subject: Subject; ilos: HeaderILos; courseActive: number }> {
    const subjectResonse: any = await provider.get(`/api/subjects/165.1.2./${SubhjectId}`, {
      params: {
        courseId,
      },
    });
    const subject: Subject = {
      name: subjectResonse.subject.subjectName,
      id: subjectResonse.subject.subjectId,
      lectureCount: subjectResonse.subject.lectureCount || 0,
      ilos: SubjectProvider.handleMAgicIloStructureForEdit(subjectResonse.subject.ilosData),
      active: Boolean(subjectResonse.subject.active),
      isFlexibleILOs: subjectResonse.flexibleILOsFlag,
    };

    return {
      subject,
      ilos: subjectResonse.flexibleILOsFlag
        ? subjectResonse.courseIlos
        : SubjectProvider.handleMAgicIloStructureForView(subjectResonse.courseIlos),
      courseActive: subjectResonse.courseActive,
    };
  }

  async getCourseIlos(
    appId: string,
    courseId: string,
    subjectId?: string,
  ): Promise<{ ilos: HeaderILos | FlexibleILOs; isFlexibleILOs: boolean }> {
    const subjectResonse: any = await provider.get(`/api/subjects/${appId}/ilos`, {
      params: { courseId, subjectId },
    });
    return {
      ilos: subjectResonse.flexibleILOs
        ? subjectResonse.courseIlos
        : SubjectProvider.handleMAgicIloStructureForView(subjectResonse.courseIlos),
      isFlexibleILOs: !!subjectResonse.flexibleILOs,
    };
  }

  async updateSubject(subject: Subject, courseId: string, isFlexibleILOs: boolean) {
    const drpSubjectStatus: number = subject.active ? 1 : 0;
    const txtSubjectName: string | undefined = subject.name || '';
    const numLectureCount: number | undefined = Number(subject.lectureCount);
    let backSub: { [key: string]: number | string | object } = {
      drpSubjectStatus,
      txtSubjectName,
      numLectureCount,
    };
    if (isFlexibleILOs) {
      backSub = {
        ...backSub,
        flexibleILOs: subject.ilos as object,
      };
    } else {
      const abraCadabraIlos = SubjectProvider.handleMAgicIloStructureForBAck(subject.ilos);
      const ilosNOChild: string = abraCadabraIlos.nochild;
      const ilosWithChild: string = abraCadabraIlos.withchild;
      const chkILO: string = abraCadabraIlos.silos;
      backSub = {
        ...backSub,
        ilosNOChild,
        ilosWithChild,
        chkILO,
      };
    }
    const updateResponse: { subjectId: string } = await provider.put(
      `/api/subjects/165.1.2./${subject.id}`,
      {
        courseId,
        subject: backSub,
      },
      {
        params: {
          courseId,
        },
      },
    );
    return updateResponse.subjectId;
  }

  async addSubject(subject: Subject, courseId: string, isFlexibleILOs: boolean) {
    const drpSubjectStatus: number = subject.active ? 1 : 0;
    const txtSubjectName: string | undefined = subject.name || '';
    const numLectureCount: number | undefined = Number(subject.lectureCount);
    let backSub: { [key: string]: number | string | object } = {
      drpSubjectStatus,
      txtSubjectName,
      numLectureCount,
    };
    if (!isFlexibleILOs) {
      const abraCadabraIlos = SubjectProvider.handleMAgicIloStructureForBAck(subject.ilos);
      const ilosNOChild: string = abraCadabraIlos.nochild;
      const ilosWithChild: string = abraCadabraIlos.withchild;
      const chkILO: string = abraCadabraIlos.silos;
      backSub = {
        ...backSub,
        ilosNOChild,
        ilosWithChild,
        chkILO,
      };
    } else {
      backSub = {
        ...backSub,
        flexibleILOs: subject.ilos as object,
      };
    }
    const updateResponse: { subjectId: string } = await provider.post(
      '/api/subjects/165.1.2.',
      {
        courseId,
        subject: backSub,
      },
      {
        params: {
          courseId,
        },
      },
    );
    return updateResponse.subjectId;
  }

  static handleMAgicIloStructureForBAck(ilosObject: any) {
    const ilosNOChild: Array<any> = [];
    const ilosWithChild: Array<any> = [];
    const chkILO: Array<any> = [];

    Object.keys(ilosObject).forEach((headerId) => {
      const mainIlo = ilosObject[headerId];
      const l1 = mainIlo || [];
      l1.forEach((ilo: any) => {
        if (ilo.subIloId) {
          ilosWithChild.push(ilo.iloId);
          chkILO.push(ilo.subIloId);
        } else {
          ilosNOChild.push(ilo.iloId);
        }
      });
    });

    const nochild = ilosNOChild.join(',');
    const withchild = ilosWithChild.join(',');
    const silos = chkILO.join(',');

    return { nochild, withchild, silos };
  }

  static handleMAgicIloStructureForView(ilosObject: any): HeaderILos {
    const viewStructure = {
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    };
    Object.keys(ilosObject).forEach((headerId) => {
      const mainIlo = ilosObject[headerId];
      const l1 = Object.values(mainIlo.ilos) || [];
      l1.forEach((ilo: any) => {
        const l2 = Object.values(ilo.subilos) || [];
        const iloItem: Ilo = {} as Ilo;
        iloItem.header = mainIlo.headerName;
        iloItem.headerId = headerId;
        iloItem.iloId = ilo.iloID;
        iloItem.ilo = ilo.iloDesc;
        const subIlos: Array<Ilo> = [];
        l2.forEach((subilo: any) => {
          const subItem: Ilo = {} as Ilo;
          subItem.header = mainIlo.headerName;
          subItem.headerId = headerId;
          subItem.iloId = ilo.iloID;
          subItem.ilo = ilo.iloDesc;
          subItem.subIloId = subilo.subIloID;
          subItem.subIlo = subilo.subIloID;
          subItem.subIlo = subilo.subIloDesc;
          subIlos.push(subItem);
        });
        iloItem.subIlos = subIlos;
        viewStructure[headerId].push(iloItem);
      });
    });

    return viewStructure;
  }

  static handleMAgicIloStructureForEdit(ilosObject: any) {
    const viewStructure = {
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    };
    Object.keys(ilosObject).forEach((headerId) => {
      const mainIlo = ilosObject[headerId];
      const l1 = Object.values(mainIlo.ilos) || [];
      l1.forEach((ilo: any) => {
        const l2 = Object.values(ilo.subilos) || [];
        const iloItem: Ilo = {} as Ilo;
        // iloItem.header = mainIlo.headerName.en;
        iloItem.headerId = headerId;
        iloItem.iloId = ilo.iloID;
        // iloItem.ilo = ilo.iloDesc;
        iloItem.unRemovable = ilo.unRemovable;
        const subIlos: Array<Ilo> = [];
        l2.forEach((subilo: any) => {
          const subItem: Ilo = {} as Ilo;
          // subItem.header = mainIlo.headerName.en;
          subItem.headerId = headerId;
          subItem.iloId = ilo.iloID;
          // subItem.ilo = ilo.iloDesc;
          subItem.subIloId = subilo.subIloID;
          subItem.subIlo = subilo.subIloID;
          // subItem.subIlo = subilo.subIloDesc;
          subItem.unRemovable = ilo.unRemovable;
          subItem.unRemovableSub = subilo.unRemovable;
          subIlos.push(subItem);
        });

        if (subIlos.length) {
          viewStructure[headerId].push(...subIlos);
        } else {
          viewStructure[headerId].push(iloItem);
        }
      });
    });

    return viewStructure;
  }

  async deleteSubject(appId: string, subjectId: string,
    courseId: string): Promise<{ message: Lang }> {
    const response: { message: Lang } = await provider.delete(`/api/subjects/${appId}/${subjectId}?courseId=${courseId}`);
    return response;
  }
}

export default new SubjectProvider();
