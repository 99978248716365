
























































import { Component, Vue } from 'vue-property-decorator';
import { HeaderILos } from '@/types/ilos.type';

@Component({
  props: {
    ilos: {
      type: [Object, Array],
      default: () => {
        const ilosObject: HeaderILos = {
          '1.1.': [],
          '1.2.': [],
          '1.3.': [],
          '1.4.': [],
        };
        return ilosObject;
      },
    },
    isFlexibleILOs: {
      type: Boolean,
      default: () => false,
    },
  },
})
export default class IlosViewComponent extends Vue {
  openNodes = [];
}
