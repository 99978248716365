import { SUBJECTS_ROUTES_SCOPES_RIGHTS } from '@/router/subject.router';
import { COURSES_ROUTES_SCOPES_RIGHTS } from '@/router/courses.router';
import { QUESTIONS_ROUTES_SCOPES_RIGHTS } from '@/router/questions.router';
import { REVIEW_QUESTIONS_ROUTES_SCOPES_RIGHTS } from '@/router/reviewQuestions.router';
import { EXAM_GENERATION_ROUTES_SCOPES_RIGHTS } from '@/router/ExamGeneration.router';
import { ADD_ILOS_ROUTES_SCOPES_RIGHTS } from '@/router/AddIlos.router';
import { REPORTS_ROUTES_SCOPES_RIGHTS } from '@/router/reports.router';
import { RESERVE_EXAM_ROUTES_SCOPES_RIGHTS } from '@/router/reserveExam.router';
import { APPIDS } from '.';

const routes = [
  {
    path: '/subject',
    id: '165.1.2.',
    childrensScopesRight: SUBJECTS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/course-ilos',
    id: '165.1.3.',
    childrensScopesRight: ADD_ILOS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/courses',
    id: '165.1.1.',
    childrensScopesRight: COURSES_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/questions',
    id: '165.2.1.',
    childrensScopesRight: QUESTIONS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/review-questions',
    id: '165.2.2.',
    childrensScopesRight: REVIEW_QUESTIONS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/exam-generation',
    id: '165.7.1.',
    childrensScopesRight: EXAM_GENERATION_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/reserve-exam',
    id: APPIDS.RESERVE_EXAM,
    childrensScopesRight: RESERVE_EXAM_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/reports/questions-status-stats',
    id: '165.4.1.',
    childrensScopesRight: REPORTS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/reports/course-questions-statistics',
    id: '165.4.2.',
    childrensScopesRight: REPORTS_ROUTES_SCOPES_RIGHTS,
  },
  {
    path: '/reports/exams-creators',
    id: '165.4.3.',
    childrensScopesRight: REPORTS_ROUTES_SCOPES_RIGHTS,
  },
];

export default routes;
