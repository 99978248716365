const app = {
  state: {
    activeSubSystem: '',
    activeApp: '',
    activeChildRight: '',
    displayApp: true,
    mini: false,
    userStagesData: [],
    activeStageId: '',
    isTechnicalSupport: null,
  },
  mutations: {
    openApps(state) {
      state.displayApp = true;
      // state.mini = false;
    },
    openScope(state) {
      state.displayApp = false;
      state.mini = false;
    },
    selectApp(state, payload) {
      state.activeApp = payload.appId;
      state.activeSubSystem = payload.subSystemId;
      state.activeChildRight = payload.activeChildRight;
    },
    setUserStagesData(state, payload) {
      state.userStagesData = Array.isArray(payload) ? payload : [];
    },
    setActiveStageId(state, stageId) {
      state.activeStageId = stageId;
    },
    setTechnicalSupport(state, isTechnicalSupport) {
      state.isTechnicalSupport = isTechnicalSupport;
    },
  },

};
export default app;
