const course = {
  state: {
    selectedCourseId: '',
  },
  mutations: {
    resetCourse(state) {
      state.selectedCourseId = null;
    },
    selectCourse(state, payload) {
      if (payload.course.courseId) {
        state.selectedCourseId = payload.course.courseId;
      } else {
        state.selectedCourseId = null;
      }
    },
  },
};

export default course;
