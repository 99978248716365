/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */

const enTranslate = {
  login: 'Login',
  password: 'Password',
  usrNameInput: 'user name or email',
  qbank: 'Question Bank',
  lang: 'العربية',
  forgetPassword: 'forget password? ',
  requiredField: 'this field is required',
  maxLetters: 'max letters is ',
  minLetters: 'min letters is ',
  maxValue: 'max value allowed is ',
  minValue: 'min value allowed is ',
  maxLessValue: 'Max Value Allowed Should Be Less Than ',
  equalValue: 'You must enter number equal',
  invalidInputData: 'please enter data in a right formate',
  testToken: 'testToken',
  questionsStatus: 'Question status',
  questionsCount: 'Questions count',
  ilosRelativeWeights: "Ilos' Relative Weights",
  degreesCount: 'Degrees count',
  chartPrograms: 'Programs ',
  print: 'print',
  examsStatus: 'Electronic Exams Status',
  examsCount: 'Exams Count',
  courses: 'Courses',
  subjects: 'Subjects',
  electronicCorrectionExams: 'Electronic Correction',
  questions: 'Questions',
  chartRights: 'Courses available on the system',
  Logout: 'Logout',
  langShortCut: 'ع',
  Title: 'Title',
  ILOS: 'ILOS',
  STATUS: 'Status',
  lectureCount: 'Lecture Count',
  btn_save: 'Save',
  btn_edit: 'edit',
  Active: 'Active',
  inactive: 'Inactive',
  Yes: 'Yes',
  No: 'NO',
  TRUE: 'True',
  FALSE: 'False',
  Subject: 'Subject',
  Subjects: 'Subjects',
  Search_by: 'Search by ',
  Required: 'Please add data',
  input_number_validation: 'Please add valid Number',
  Scope: 'Scope',
  No_subjects: 'No subjects could be found',
  Add_subject: 'Add subject',
  add: 'Add',
  delete: 'delete',
  EXAM_DATA: 'Exam Data',
  EXAM_TITLE: 'Exam title',
  EXAM_TYPE: 'Exam type',
  ACADIMIC_YEAR: 'Academic Year',
  TERM: 'Term',
  DEGREE: 'Degree',
  SHUFFLE_OPTIONS: 'Shuffle Options',
  TIME_IN_MINUTES: 'Time (minutes)',
  QUESTION_LANGUAGE_F: 'Language',
  HAS_ADD_DEGREES: 'Has Additional Degrees',
  TRAINING_QUESTIONS: 'Training Questions',
  EXPOSURE_LIMIT: 'Question Exposure Limit',
  MODELS_MATCH: 'Models Match',
  COURSE_INSTRUCTOR: 'Course Instructor',
  COURSE_CODE: 'Course Code',
  CREATED_BY: 'Created By',
  REVIEWED_BY: 'Reviewed By',
  EXAM_DIFFICULTY: 'Exam Difficulty',
  SIGNATURE: 'Instructor Signature',
  EASY: 'Easy',
  MEDIUM: 'Medium',
  HARD: 'Hard',
  onRequiredRatio: 'equal to RequiredRatio',
  underRequiredRatio: 'less than RequiredRatio',
  overRequiredRatio: 'more than RequiredRatio',
  MODEL_NUMBER: 'Model Number',
  optionsCount: 'options count',
  instructions: {
    validExcel: 'File formate must be xlsx-xls and size should be less than 5 MB',
    validExcelColumn_A: 'Column A should contains course name in Arabic',
    validExcelColumn_B: 'Column B should contains course name in English',
    validExcelColumn_C: 'Column C should contains study plan name in Arabic',
    validExcelColumn_D: 'Column D should contains study plan name in English',
    validExcelColumn_E: 'Column E should contains study plan id ',
    validExcelColumn_F: 'Column F optional and should contain course code',
    validExcelOrder:
      'Data should follow the pattern (A-B-C-D-E-F) and any additional data considered incorrect',
  },

  QuestionImportModelInstructions: {
    MODEL_INSTRUCTIONS:
      '- File must be XML formate, exported from Moodle system and questions must be in the form of (MCQ - T&F - Short Answers)',
    MOODLE_INSTRUCTIONS_2:
      '- File must contain the following question types (True Or False - Article - MCQ).',
    MOODLE_INSTRUCTIONS_3: '- File contents encryption must be UTF-8.',
  },
  QuestionImportQmansInstructions: {
    MOODLE_INSTRUCTIONS_3: '- File contents encryption must be UTF-8.',
    QMANS_INSTRUCTIONS: '- File must be XML formate, exported from Qmans system',
    QMANS_INSTRUCTIONS_2: "- File's Size mustn't exceed 5 MB",
    QMANS_DOC_INSTRUCTIONS: {
      FILE_SIZE: '- File max size is 5MB.',
      FILE_TYPE: '- File must be of type (.docx) or (.txt) only.',
      QUESTIONS_TYPES: '- Questions must be of type (MMCQ) or (MCQ) only.',
      QUESTIONS_START: '- Every question should start with (----------).',
      QUESTIONS_ANSWERS: '- Every answer should start with capital letters followed by (:), like ("A:", "B:", "C:", "D:", "E:", "ANSWER:").',
      CORRECT_ANSWERS: '- Correct answers should present in question answers in the same letter case.',
      MULTI_ANSWERS: '- If there are multi answers separate them with (,).',
      QUESTION_TYPE: '- Question type depends on answers count on every question.',
    },
    QMANS_EXCEL_INSTRUCTIONS: {
      FILE_TYPE: '- File must be of type (.xlsx) only.',
      QUESTIONS_STEM: "- Column (A) contains question's stem",
      QUESTIONS_CORRECT: `
        - Column (B) contains question's correct answers' order (1:5)
        and it should be comma separated in case of MMCQ questions like (1,3)`,
      QUESTIONS_OPTIONS: "- Columns from (C) to (G) contains question's options",
    },
    QMANS_TXT_INSTRUCTIONS: {
      FILE_TYPE: '- File must be of type (.txt) only.',
      QUESTIONS_TYPES: '- File can contain the following question types (True Or False - MCQ - MMCQ).',
    },
  },
  titles: {
    CURRENT_QUESTION: 'Current Version',
    OLD_VERSIONS: 'Old Versions and Reports',
    COURSE_STATISTICS: 'Course statistics',
    ELECTRONIC_EXAMS: 'Electronic Exams',
    reservationActions: {
      add: 'Add reservation',
    },
    viewRequestDetails: 'View Request Details',
    viewImportDetails: 'Imported Questions Details',
    subQuestionsFirstColumn: 'Sub Questions (First Column)',
    answersSecondColumn: 'Answers (Second Column)',
    COURSE_QUESTIONS_STATISTICS:
      'Repoet on the diversity of degrees of questions difficulty degrees for',
    showAddQuestionDialog: 'Add Question',
    AddExam: 'Add Exam',
    forExam: 'For Exam',
    examModelDataForNumber: 'Data Of Model Number',
    courses: 'Courses',
    addCourse: 'Add Course',
    infoMessage: 'Explanatory Message',
    sessionExpiryMessage: 'Your session is about to expire, any data entries will not be saved. To continue your work, please extend the session by pressing the confirm button before the end of the timer.',
    details: 'Details',
    courseDetails: 'Course Details',
    addedCourses: 'Added Courses',
    editedCourses: 'Edited Courses',
    inactiveCourses: 'Deactivated courses',
    confirmMessage: 'Confirmation Message',
    sessionExpiryWarning: 'Session Expiry Warning',
    createQuestions: 'Create Questions',
    QuestionsData: 'Questions Data',
    Exams: 'Exams',
    ReserveExam: 'Reserve Exams',
    questionDetails: 'Question Details',
    addQuestion: 'Add Question',
    questionAction: {
      add: 'Add Question',
      copy: 'Copy Question',
      edit: 'Edit Question',
      addCaseStudy: 'Add Case Study Question',
      copyCaseStudy: 'Copy Case Study Question',
      editCaseStudy: 'Edit Case Study Question',
    },
    questionReview: 'Question Review',
    reviewQuestion: 'Review Questions',
    questionImport: 'Import Questions to Course',
    FileInstructions: 'Import File instructions',
    ImportInstructors: 'Add Instructors',
    chooseLang: 'Choose Language',
    questionsStatusStatsReport: 'Questions Status Statistics Report',
    examsCreatorsReport: 'Electronic Exams Creators Report',
    examAction: {
      add: 'Add Model',
      edit: 'Edit Model',
      copy: 'Copy Model',
      mainQuestionTitle: 'Main Question Title',
    },
    examMatrix: 'Exam Matrix',
    EXAM_MODEL_STATISTICS: 'Model Statistics',
    ExamDetails: 'Exam Details',
    ExamPaper: 'Exam Paper',
    ExamMatrix: 'Exam Matrix',
    IMPORT_QUESTIONS_DIALOG: 'Import Questions',
  },

  tableHeaders: {
    subILOCode: 'sub ILO code',

    subIloName: 'Sub ILO',

    examTitle: 'Exam Title',
    academicYear: 'academic Year',
    examType: 'Exam Type',
    examStatus: 'exam Status',
    semester: 'Semester',
    turn: 'Turn',
    createdAt: 'Created At',
    index: 'Index',
    courseName: 'Course Name',
    studyPlan: 'Study Plan',
    courseType: 'Course Type',
    IHId: 'Ibn Haitham Id',
    actions: 'Actions',
    studyPlanId: 'Study Plan Number',
    courseCode: 'Course Code',
    questionTitle: 'Question Title',
    ILOs: 'ILOs',
    questionType: 'Question Type',
    subject: 'Subject',
    questionStatus: 'Question Status',
    questionReviewDate: 'Question Review Request Date',
    notes: 'notes',
    name: 'name',
    reason: 'reason',
    questionLanguage: 'Question Language',

    complete: 'Complete',
    incomplete: 'Incomplete',
    pendingReview: 'Pending Review',
    approved: 'Approved',
    rejected: 'Rejected',
    deleted: 'Deleted',

    totalCount: 'Total Count',
    totalQuestionsCount: 'Total Questions Count',
    totalDegreesCount: 'Total Degrees Sum',
    questionsAndDegrees: 'Questions & Degrees',
    questionsCount: 'Questions Count',
    degreesSum: 'Degrees Sum',
    degreesMid: 'Average degree of the question',
    timeMid: 'Average question time (in minutes)',
    relativeWeights: "Subejcts' Relative Weights ",
    subjectWeight: "Subjects' Relative Weight",
    iloWeight: "Ilos' Relative Weight",
    questionNumber: 'Count Of Questions',
    COUNT_OF_ANSWERS: 'Number Of Choices',
    totalDegreesSum: 'Total Degrees Sum',

    reserveTableExamType: 'Exam Type',
    requestDate: 'Request Date',
    day: 'Day',
    requestStatus: 'Request Status',
    center: 'Center',
    errorMessage: 'Error Message',
    questionData: 'Question Data',
    PRIORITY: 'Priority',
    LIMITED_SECTIONS_CAPACITY: 'Limited Sections Capacity',
    UNLIMITED_SECTIONS_CAPACITY: 'Unlimited Sections Capacity',

    DIFFICULTY_INDEX: 'Difficulty Index',
    DISCRIMINATION_INDEX: 'Discrimination Index',

    DIFFICULTY_INDEX_AVERAGE: 'Difficulty Index Average',
    DISCRIMINATION_INDEX_AVERAGE: 'Discrimination Index Average',

    MIN_TIME: 'Min TIme (Minute)',
    MAX_TIME: 'Max Time (Minute)',
    MEAN: 'The Mean (Minute)',
    MEDIAN: 'The Median (Minute)',
    MODE: 'The Mode (Minute)',
    STANDARD_DEVIATION: 'Standard Deviaition (Minute)',
    COEFFICIENT_OD_VARIATION: 'Coefficient of Variation',

    MIN_TIME_AVERAGE: 'Min TIme Average (Minute)',
    MAX_TIME_AVERAGE: 'Max Time Average (Minute)',
    MEAN_AVERAGE: 'The Mean Average (Minute)',
    MEDIAN_AVERAGE: 'The Median Average (Minute)',
    MODE_AVERAGE: 'The Mode Average (Minute)',
    STANDARD_DEVIATION_AVERAGE: 'Standard Deviaition Average (Minute)',
    COEFFICIENT_OD_VARIATION_AVERAGE: 'Coefficient of Variation Average',

    SUCCESS_RATE: 'Success Rate',
    DEGREE_GIVEN_TO_ALL: 'Degree given to all',

    DATE_TIME_FROM: 'Date / Time From',
    DATE_TIME_TO: 'Date / Time To',
  },

  labels: {
    MODEL_CODE: 'Model Code',
    CONFIRMATION_RESTRICTIONS: 'The following must be approved before publishing the exam',
    CHOOSE_SECTORS: 'Select the sectors you want to distribute the exam to',
    DISTRIBUTION_WARNING: 'After distribution approval redistribution will be unavailable, and exam and models data cannot be modified',
    MODEL_CODE_EXAMPLE: 'Model code example',
    ITEM_ANALYSIS: 'Item Analysis',
    ANSWER_TiME_DISTRIBUTION: 'Answer Time Distribution',
    QUESTION_SUCCESS_RATE: 'Question Success Rate',

    STUDENTS_COUNT: 'Students Count',
    EXTRA_STUDENTS_COUNT: 'Extra Students Count',
    MODIFIED_STUDENTS_COUNT: 'Total Required Students Count',
    TOTAL_STUDENTS_COUNT: 'Total Students Count = Imported Students Count + Extra Students Count',
    TOTAL_STUDENTS_HINT: 'Insert value only if you need different number of students',
    TOTAL_STUDENTS: 'Total Students Count',
    STUDENTS_TYPE: 'Students Type',
    centers: 'Exam Centers',
    CENTERS_CAPACITY: 'Exam Centers (Limited Sections Capacity) (Unlimited Sections Capacity)',
    LIMITED_SECTIONS_CAPACITY: 'Limited Sections Capacity',
    UNLIMITED_SECTIONS_CAPACITY: 'Unlimited Sections Capacity',
    requestTimeFrom: 'request time from',
    requestTimeTo: 'request time to',
    STATISTICS_TYPE: 'Statistics Type',
    correctAnswerIndex: 'correct answer index',
    AddExam: 'Add Exam',
    timeInMinutes: 'Time (minutes)',
    ExamBasicData: 'Exam Basic Data',
    AddQuestions: 'Add Questions',
    languages: 'languages',
    next: 'next',
    ExamTitle: 'Exam Title',
    pervious: 'pervious',
    examDate: 'Date of Exam',
    examSignature: 'Instructor Signature',
    examDifficulty: 'Exam Difficulty',
    modelsMatchOptions: 'Models Match',
    mediumPercentage: 'medium',
    degree: 'degree',
    easy: 'easy',
    difficult: 'difficult',
    addDegree: 'Has Additional Degrees',
    examPeriod: 'Period',
    exposureLimit: 'Question Exposure Limit',
    instructor: 'instructor',
    academicYears: 'Academic Years',
    semesters: 'semesters',
    turns: 'Turn',
    trainingOptions: 'training Options',
    shuffleOption: 'Shuffle Option',
    examStatus: 'Exam Status ',
    reserveExamStatus: 'Exam Request Status',
    center: 'Exam Center',
    ExamType: 'Exam Type',
    examDuration: 'Exam Duration',
    examTotalStudents: 'Total Students',
    examAttendedStudents: 'Total Attendence',
    VALID_EXPORTED_QUESTIONS_COUNT: 'Count of valid questions',
    VALID_EXPORTED_QUESTIONS: 'Valid Questions',
    NOT_VALID_EXPORTED_QUESTIONS_COUNT: 'Count of not valid questions',
    NOT_VALID_EXPORTED_QUESTIONS: 'Not Valid Questions',
    login: 'Login',
    syncCourses: 'update Courses',
    SYNC_CREDIT_COURSES: 'update credit Courses',
    SYNC_ACADEMIC_COURSES: 'update Academic Courses',
    SYNC_PGS_COURSES: 'update Pgs Courses',
    about: 'About',
    process: 'System Process',
    services: 'Services',
    users: 'Users',
    hintCaseStudy: 'In Progress',
    hintMongoQues: 'Can\'t be exported to bsheet',
    policy: 'Policy',
    sysSolution: 'The integerated solution for academic exams',
    qmansSys: `Qmans system is a web-based application that aims to get
    academic staff members together to build a question bank
    which could be used later in exams generation .`,
    sysGoal:
      "The system's goal is to generate exams regarding a set of agreed standards, to achieve that the system provides a set of functionalities that helps academic staff to manage and control courses, subjects, learning objectives and questions that will be used to generate exams .",
    readMore: 'Read more',
    startNow: 'Get started now',
    courseName: 'Course Name',
    studyPlan: 'Study Plan',
    courseType: 'Course Type',
    IHId: 'Ibn Haitham Id',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    noData: 'No Data!',
    noExamsData: 'No Exams Data For This Course!',
    noScopeSelected: 'Choose correct scope',
    noCourseSelected: 'No course selected',
    noValidQuestions: 'No valid questions to import!',
    loading: 'Loading... Please wait',
    addMethod: 'Add Method',
    academicYear: 'Academic Year',
    addFromService: 'Import from Ibn Hythem',
    addFromExcel: 'Add From Excell',
    addFromSystem: 'Add from system',
    arCourseName: 'Course Name In Arabic',
    enCourseName: 'Course Name In English',
    arStudyPlan: 'Study Plan Title In Arabic',
    enStudyPlan: 'Study Plan Title In English',
    studyPlanId: 'Study Plan Number',
    courseCode: 'Course Code',
    sortBy: 'Sort By',
    questionTitle: 'Question Title',
    examTitle: 'Exam Title',
    ILOs: 'ILOs',
    questionType: 'Question Type',
    subject: 'Subject',
    questionStatus: 'Question Status',
    difficultyLevel: 'Difficulty Level',
    DIFFICULTY_LEVEL_IN_EXAMS: 'Average Difficulty in Exams',
    AVERAGE_TIME_IN_EXAMS: 'Average Time in Exams',
    NEED_TO_FIX: 'Need To Fix',
    TIME_AND_DIFFICULTY: 'Time and Difficulty Level',
    TIME: 'Time',
    ilosHeader: ' ILOs Header',
    createdBy: 'Created By',
    select: 'select',
    questionLanguage: 'Question Language',
    questionDegree: 'Question Degree',
    questionTime: 'Question Time',
    questionText: 'Question Text',
    questionAnswers: 'Question Answers',
    questionAnswer: 'Question Answer',
    correctAnswer: 'Correct Answer',
    questionRubrics: 'Question Rubrics',
    questionExposureLimit: 'Question Exposure Limit',
    evaluations: 'Evaluations',
    feedback: 'Feedback',
    training: 'Training',
    no: 'No',
    yes: 'Yes',
    rubricText: 'Rubric Text',
    rubricWeight: 'Rubric Weight',
    subQuestion: 'Sub Question',
    answerLinesCount: 'Number Of Answer Lines',
    questionTypes: {
      TF: 'T&F',
      mcq: 'MCQ',
      mmcq: 'MMCQ',
      essay: 'Essay',
      CS: 'CaseStudy',
      MATCH: ' Matching Question',
    },
    timeUnits: {
      second: 'Second',
      minute: 'Minute',
      hour: 'Hour',
    },
    language: 'Language',
    noSubjectIlos: 'This Subject does not contain ILOs !',
    noHeaderIloSelected: 'Header ILO has not been selected!',
    reviewDateFrom: 'Review Request Date From ',
    reviewDateTo: 'Review Request Date To',
    createQuetionDateFrom: 'Creation Date From ',
    createQuetionDateTo: 'Creation Date To',
    revisionQuetionDateFrom: 'Revision Date From ',
    revisionQuetionDateTo: 'Revision Date To',
    createDateFrom: 'Create Date From ',
    createDateTo: 'Create Date To ',
    examRequestFrom: 'Exam Request Date From',
    examRequestTo: 'Exam Request Date To',
    fileType: 'File type',
    no_data: 'no data',
    questionTimeline: 'Question Timeline',
    questionStatusChanged: 'Question status changed from',
    questionAdded: 'Question added with status',
    to: 'to',
    by: 'by',
    date: 'on date',
    equationsInstructions:
      'In case of using equations, this button will redirect you to the guide,',
    latexInstructions: `
      you should use latex within the equation editor and it would be processed automatically
      (Hint: Equations can not include 'begin' or 'end')`,
    lang: {
      ar: 'Arabic',
      en: 'English',
    },
    coursesStatus: 'Courses Status',
    tablesDist: 'Tables Distribution',
    fromDate: 'From Date',
    toDate: 'To Date',
    answersCount: 'Number Of Answers',
    questions: 'Questions',
    exams: 'Exams',
    degrees: 'Degrees',
    totalQuestions: 'Total Questions',
    totalDegrees: 'Total Degrees',
    reset: 'Reset',
    ILOS_OPTIONS: 'ILOs Type',
    OK_BTN: 'Ok',
    RESET_BTN: 'Reset',
    CUSTOM_ILOS: 'Custom ILOs',
    FIXED_ILOS: 'Fixed ILOs',
    DELETEDISABLED: 'Cannot delete due to related topics',
    RESET_DISABLED: 'Cannot reset due to related subjects',
    round: 'Automatic Round',
    COUNT_OF_QUESTIONS: 'Count Of Questions',
    COUNT_OF_ANSWERS_OPTIONS: 'Count Of Choices',
    TOTAL_CENTER_COUNT: 'Total Center Count',
    LIMITED_CENTER_COUNT: 'Limited Sections Count',
    UNLIMITED_CENTER_COUNT: 'Unlimited Sections Count',
    Answers: 'Answers',
    Manual: 'Manual',
    autoGenerated: 'Auto Generated',
    electronicCorrection: 'Electronic Correction',
    electronicExam: 'Electronic Exam',
    chooseExamType: 'select exam generation type',
    choosePublishType: 'Select Publish Exam Option',
    publishWarning: 'Warning',

    examAction: {
      deactivatedSubjectwarning: 'Subject assigned to this question has been deleted',
    },
    degreeFrom: 'degree from',
    degreeTo: 'degree to',
    timeFrom: 'Time from',
    timeTo: 'Time to',
    degreesDistribution: 'Degrees Distribution',
    time: 'Time',
    difficulty: 'Difficulty',
    similarityRatio: 'Similarity Ratio',
    modelNo: 'Model No',
    QUESTION_MARK: 'Question Mark',
    QUESTION_TIME: 'Question Time',
    invalidMatch: 'Used questions must exactly match predefined exam questions data',
    SHOW_EXAM_DETAILS: 'Click to show exam details',
    EXAM_DETAILS: 'Exam Details',
    SKIP_OPTION: 'Skip Option',
    AVAILABLE_TO_DATE: 'Available To',
    GIVE_FULL_MARK_HINT: 'The question degree is given to all students or becomes zero in this exam',
    easyQuestions: 'Easy questions',
    medQuestions: 'Medium questions',
    hardQuestions: 'Hard questions',
    totalDegree: 'Total Degree',
    totalTime: 'Total Time',

    degreeError: 'please enter degree correctly',
    ARRANGE_PRIORITIES: 'Centers Priorities',
    UPGRADED_VERSION: 'The question has been upgraded from version %{from} to %{to}',
    VERSION: 'Version',
    DRAW_ANSWERS_LINES: 'Draw answer lines',
    USE_QUESTIONS_NEED_UPDATE: 'Use questions that need updating',
    EXPOSURE_LIMIT: 'Exposure limit less than',
    EXAMS_CREATORS: 'Exams creators',
  },

  buttons: {
    next: 'Next question',
    previous: 'Previous question',
    importStudent: 'import students count',

    viewCourse: 'view course ',
    add: 'Add',
    addQuestion: 'Add New Question',
    copyExam: 'copy exam setting',
    publish: 'Publish',
    distribute: 'Sectors Distribution',
    isDistributed: 'Sectors distributed',
    scope: 'Scope',
    reset: 'Reset',
    addExam: 'add Exam',
    search: 'Search',
    back: 'Back',
    inputFile: 'Input File',
    ok: 'OK',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    confirm: 'Confirm',
    import: 'Import',
    export: 'Export',
    copy: 'Duplicate',
    delete: 'Delete',
    preview: 'Preview',
    exportQuestions: 'Export Questions',
    edit: 'Edit',
    simulate: 'Simulate',
    askReview: 'Ask For Review',
    addRubric: 'Add Rubric',
    removeRubric: 'Remove Rubric',
    addAnswer: 'Add Answer',
    removeAnswer: 'Remove Answer',
    reject: 'Reject',
    approve: 'Approve',
    importIlos: 'Import Ilos',
    resetIlos: 'Reset ilos relations',
    templateFile: 'Questions Templates File',
    open: 'Open',
    show: 'Show',
    print: 'Print',
    previewCourse: 'Preview Course',
    examAction: {
      addSpec: 'Add main question',
      deleteQuestion: 'delete question',
      replaceQuestion: 'replace to similar question',
      addQuestion: 'add question',
    },
    addModel: 'Add model',
    more: 'more',
    showExamBlueprint: 'Show Exam Blueprint',
    applyDistribution: 'Apply Distribution',
    equalDistribution: 'Equal Distribution',
    yes: 'Yes',
    no: 'No',
    copyAndShuffle: 'Copy and Shuffle',
    onlyCopy: 'Only Copy',
    addSubQuestion: 'Add Sub Question',
    uploadQuestions: 'Upload Questions',
    exportNotValidQuestions: 'Export Not Valid Questions',
  },

  validations: {
    codePattern: 'The code must contain two numbers from 00 to 99',
    periodPattern: 'The period must be larger than or equal to 1 and less than or equal to 9',
    required: 'This field is required',
    maxLength: 'This field can contain at most %{max} characters',
    minLength: 'This field can contain at least %{min} characters',
    maxValue: 'max value allowed is %{max} ',
    minValue: 'min value allowed is %{min} ',
    invalidFileType: 'Invalid File Type',
    invalidFileSize: 'File size must be at most %{max} MB',
    integerValue: 'This field must contain an integer value',
    REQUIRED_FIELD: 'You Should Enter The Field %{fieldName}',
    NOT_ALLOWED_QUESTION_TYPE: 'You can not add questions of this type, as there are no active questions',
    MAX_NUMBER_QUESTIONS: 'Max Number Of Questions Is %{max} Question',
    NOT_EQUAL_ZERO: 'Number Should Not Be Zero',
    NEGATIVE_NUMBER: 'Number Should Not Be Negative',
    NO_QUESTIONS: 'There Is No Questions On This Course',
    iloLinkToCourse: 'click here to go to course page to import Ilo',
    NO_FRACTION: 'Number Should Not contain Fraction',
    invalidModelTotalDegrees:
      'The number of degrees entered does not equal to the total number of exam degrees',
    maxFloating: 'Max floating numbers count is 2',
    CHOOSE_ONE_AT_LEAST_VALIDATION: 'You should choose at least one question to be able to upload',
    CHOOSE_ONE_ILOS_AT_LEAST_VALIDATION: 'You should choose at least one ilo to be able to upload',
  },

  messages: {
    reservationDate: 'Start time should be bigger than now',
    reservationDateDiff: 'End date should be bigger than start date',
    sureImport: 'Are you sure you want to import these data ?',
    sureDelete: 'Are you sure you want to delete this Item ?',
    SURE_DELETE_ILO: 'All sub-ILOs will be deleted, Are you sure to delete this item?',
    sureCopy: 'Are you sure you want to copy this exam',
    copyWithModels: 'Copy models',
    sureSave: 'Are you sure you want to save this ?',
    sureBack: 'Are you sure? your changes would be discarded!',
    sureRejectQuestion: 'Are you sure you want to reject this question ?',
    sureApproveQuestion: 'Are you sure you want to approve this question ?',
    sureOpen: `
      Are you sure you want to open this question ?
      Status would change to Complete, thereby you won't be able to use it in exams' creation any more
    `,
    noSimilarQuestions: 'No similar questions found',
    modelSimilarity: 'Highest similarity ratio for this model is',
    sureSaveModel: 'Are you sure you want to save this model ?',
    noMatrixYet: 'Exam has no matrix yet',
    noModels: 'There Is No Models',
    noQuestions: 'There Is No Questions',
    copyAndShuffleMode:
      "Do you want to copy the model then shuffle it based on exam's shuffle settings, or just copy ?",
    sureGoStepBack: 'Changes on this step would be discarded, Are you sure ?',
    sureDeleteSubQuestionsILOS: 'All Ilos on sub questions would be deleted, Are you sure ?',
    noSubQuestions: "There is no sub questions, You wouldn't be able to save",
    selectQuestionsFirst: 'You should select questions first to be able to delete',
    sureDeleteSelected: 'Are you sure you want to delete %{num} questions',
    sureSaveSelected: 'Are you sure you want to save %{num} questions',
    CONFLICT_BOOKED_TIME_SLOTS: 'The following centers have booked time slots that intersect with your reservation',
    addAprovalQuesions: 'The questions will be added with approved status',
    cannotDeleteCourse: 'Cannot delete the course',
    cannotDeleteCourseExtraInfo: 'Cannot delete the course that contains subjects, ILOs, questions, or exams',
  },

  answer: 'answer',
  questionText: 'question text',
  answerText: 'answer text',

  RowsPerPage: 'Rows per page',
  ALL: 'All',
  Rights: 'All rights reserved',
  CITC: 'Communications and Information Technology Center',
  Mans: 'Mansoura University',
  LoggedInSuccessfully: 'You have been logged in successfully',
  email: 'Email',
  notifications: 'Notifications',
  changeLng: 'change language',
  QuestionBank: 'Question Bank',
  Of: 'of',
  QuestionType: 'Question Type',
  CaseStudy: 'Case Study',
  QuestionText: 'Question Text',
  QuestionTitle: 'Question Title',
  QuestionLang: 'Question Lang',
  ForTraning: 'For Traning',
  NextStep: 'Next Step',
  selectSubject: 'select subject',
  selectLang: 'select language',
  richTextMaxLength: 'This field can contain at most {max} characters',
  richTextLargeFile: 'File upload cancelled because it will exceed the max capacity limit of 3 MB',
  questionBasicData: 'question basic data',
  subQuestions: 'sub questions',
  perviousStep: 'pervious step',
  courseDetails: 'Course Details',
  subjectStatus: 'Subjects Status',
  assessment: 'Assessments',
  ilos: 'Ilos',
  generatedExams: 'Generated Exams',
  electronicExams: 'Electronic Exams',
  courseId: 'Course Id',
  courseName: 'Course Name',
  planName: 'Study Plan',
  courseType: 'Course Type',
  courseCode: 'Course Code',
  containsIlos: 'Contains Ilos',
  yes: 'Yes',
  no: 'NO',
  containsExams: 'Contains Exams',
  activate: 'Activate',
  deactivate: 'Deactivate',
  subjectsDeactivate: 'Subjects on this course will be deactivated, Are you sure?',
  subjectsActivate:
    'Please select the subjects you want to activate then click confirm or cancel to remain inactive ',
  courseDeactivateConfirm: 'Do you want to deactivate this course ?',
  courseActivateConfirm: 'Do you want to activate this course ?',
  deleteCourseConfirm: 'Do you want to delete this course ?',
  courseEditDisabled: "Subject cant't be activated as course is inactive ",
  EXPORT_QUESTIONS_COUNT:
    "Questions Will Be Exported According Due To Search Filters and it's count is %{count} ",
  loginInfo: {
    User: ' Active User',
    question: 'Question',
    unversity: 'Faculty and Institution',
    sysUsers: 'System Users',
    exam: 'Exam',
    services: 'Our services',
    coursesManagement: 'Courses management',
    coursesManagementDetails1:
      'Define and manage a set of available courses inside your organization, a set of course statistics available that display the current status of the course, related subjects, questions, objectives and exams also We provide Full API integration with Ibn Al Haytham systems for ',
    creditHours: 'Credit hours',
    academicYear: 'Academic Year',
    or: 'or',
    coursesManagementDetails2: ' programs to import courses, assessments and students.',
    subjectsManagement: 'Subjects management',
    subjectsManagementDetails:
      "Define and manage different subjects for different courses, specify the learning objectives for each subject from the learning objectives that have been added with the course, determine the number of lectures for the subject which could be used in exam's  blueprint construction, and these subjects can then be used to create questions and exams .",
    questionCreation: 'Question creation',
    questionCreationDetails:
      'Create and manage questions of different types including (MCQ, MMCQ, T&F, Essay, Case study), system support mathmetical equations and chemical formulas, users can export questions to xml format and import them on differnet subjects, import questions from moodle with xml format, send questions to be reviewed by reviewers .',
    questionReview: 'Question review',
    questionReviewDetails:
      "Question reviewers review questions from different points of views including literal, scientific review and verify that the questions' information are related and correct, then questions are approved or rejected and feedback is sent to question creator, after questions are approved they can be used in generating exams .",
    questionHistory: 'Question history',
    questionHistoryDetails:
      'Question history create a log history for changes made on question, any change in the question information will be logged, the old version of the question will be copied and moved to new record –new version- and the updated question will be the latest version. User can access any old version of question history.',
    examDeliveryDetails:
      'Exam can be delivered to students as a bubble sheet exam which is then corrected by a desktop application using image processing and the answers are exported back to web application , or as an online exam which students can access , answer the exam and the exam is graded automatically . ',
    examDelivery: 'Exam delivery',
    dataPrivacy: 'Data privacy and security policy',
    policy1: 'All the data registered on the system belongs to the organisation owning this data .',
    policy2:
      "Citc engineers aren't allowed to view these data except at extreme necessity and technical support only by prior agreement with the organisation owning these data .",
    policy3:
      'No statement of the system data is given to any individual or sub-agency belonging to the organisation that owns the data or to any other party without  written consent from the organisation that owns the data.',
    policy4:
      'The data registered on the system can be used to generate statistics that can be used to measure the performance of the system and the efficiency of its use by its users .',
    policy5:
      "All data on the system is secured by securing the server that hosts the data and by securing the transmission of data between the user's device and the server , as well as securing the system interface that the user uses .",
    policy6:
      'Each user of the system is fully responsible for his password, and  must take all possible precautions so that no one else can obtain it .',
    StructureDefinition: 'Structure Definition',
    structionDescription:
      "Define and manage the learning structure within the organization whether it is a faculty or an institution and it's related courses, subjects and learning objectives .",
    BankCreation: 'Bank creation',
    BankCreationDescription:
      'Create and manage various questions with different types (essay - true or false - mcq - mmcq), review these questions and then approve them or reject them .',
    ExamsCreation: 'Exams Creations',
    ExamsCreationDescription:
      'create exams using approved questions from question bank, assign students to exams and then deliver these exams either as bubble-sheet exams or online exams ',
    ReportExtractions: 'Reporting',
    ReportExtractionsDescription:
      'Extract and view various reports on different items in your orgainzation, including courses, learning objectives, exams, answers, students and faculties .',
  },

  '01.': '01.',
  '02.': '02.',
  '03.': '03.',
  '04.': '04.',
  importFaildQuestion: 'Failed Questions',
  importSuccesdQuestion: 'Added Question',
  selectFile: 'Select file',
  BackHome: 'Back Home',
  Pagenotfound: 'Page Not Found',
  importCoursesLevelRights: 'Only the rights related to courses will be imported for the user',
  userAddMasterRule:
    'Imported users will grand the rights provided in master rule in your faculty, your master rule is %{name}',
  importSuccesdInstructors: 'Sucessfully added instructors',
  importFaildInstructors: 'Falid to add instructors',
  no_master_rule:
    "faculty don't have master rule to add rights for imported user, please ask you faculty system supervision manager to define master rule",
  no_ilos: 'no ilos',
  bsheetAlert: 'For electroninc correction servises please visit click ',
  here: 'here',
  resetIlosAlert1:
    'question ilos will be deleted and all course questions status will be changed to not completed questions including approved questions, are you sure ?',
  resetIlosAlert2: 'subject ilos will be deleted are you sure',
  rePublishAlert: 'Warning !! This Exam Was Previously Published .',
  NoQuestionsError: 'Can not add an exam for a course with no questions',
  OnlyExternalCourse: 'there is no ilos import ilos from courses page ',
  AddIloRights: 'Have no permission to add ilos ',
  CountIloValidation: 'Course must have at least one ilo',
  isCourseActive: 'Course is not active',
  NoApprovedQuestions: 'This course may not contain active subjects , ILOS or approved questions .',
  arName: 'Name in Arabic',
  enName: 'Name in Inglish',
  foundation: 'Foundation',
  jobEndDate: 'Job End Date',
  nationalId: 'National ID',
  changePassword: 'Change Password',
  userName: 'Username',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  WEAK_PASSWORD:
    'Weak Password - Password must contain : At least eight characters.  One or more of each of the following: lower-case letter ,upper-case letter ,number ,special character',
  NOT_CONFIRMED: 'passwords are not matched',
  profile: 'Profile',
  notInserted: 'not inserted',
  Note: 'Note',
  Email: 'Email',
  simulateQuestion: 'Simulate Question',
  theAnswer: 'question answer',

  coursesTip: 'Count of courses you are authorized on courses application',
  questionsTip: 'Count of questions created by',
  examsTip: 'Count of exams created by',
  subjectsTip: 'Count of subjects related to courses you are authorized on subjects application',
  fileReaderValidationError: 'Sorry, FileReader API is not supported',
  imgTypeValidationError:
    "Sorry, Can't upload this photo . please check that image foramt is jpg or jpeg or png",
  imgSizeValidationError: "Sorry, Maximum size shouldn't exceed 1MB",

  statusReportCourseStatus: {
    allCourses: 'All Courses',
    hasQuestions: 'Courses With Questions',
    hasNoQuestions: 'Courses With No Questions',
  },

  statusReportDistributions: {
    byFaculty: 'By Faculty',
    byDepartment: 'By Department',
    byGrade: 'By Grade',
  },

  SelectProfileImg: 'Select Profile Image',
  Done: 'Done',
  invalidDegreesTotal:
    'The number of degrees entered does not equal to the total number of exam degrees',
  invalidQuestionsTotal:
    'The number of questions entered does not equal to the total number of exam questions',
  zeroQuestion:
    "The number of questions can't be 0 for a corresponding number of degrees greater than 0",
  zeroDegree:
    "The number of degrees can't be 0 for a corresponding number of questions greater than 0",
  iloSelectedZero: "A selected ILO weight can't be zero",
  cellDetails: 'Cell Details',
  noData: 'No Data',
  integarInvalid: 'Please enter an integer',
  enterQuestionsFirst: 'Please complete the second step to be able to edit the matrix',
  NO_STUDENTS_TO_WITHDRAW: 'There is no students to import',

  QUESTIONS_DETAILS: {
    CELL_DETAILS: 'Course Details',
  },
  answerDuration: 'answer duration',
  CHARTS_HEADER: {
    SUBJSTATS: 'Course Subjects Coverage',
    DIFFSTATS: 'Difficulty Levels Coverage',
    ILOSTATS: 'Course ILOS Coverage',
    TYPESTATS: 'Question Types Coverage',
  },
  SUB_ILOS: 'sub ILOs',
  maxILOs: "Can't add more than 10 Ilos",
  minILOs: 'Course should include one Ilo at least ',
  model_percentages_chart_title: 'Exam criteria fulfillment percentages',
  maintenanceNotice:
    'Please note that the system will be down today, Thursday, March 17, 2022 at 5 in the evening, in order to make some updates to raise its efficiency.',
  deprecatedEquationFormat:
    'The following text contains mathematical data format that is no longer supported. Please re-enter the text.',
  IMPORT_ALL_STUDENTS_OPTION: 'All Students',
  IMPORT_FRESHMEN_OPTION: 'Fresh Student',
  IMPORT_REMAINING_STUDENTS_OPTION: 'Retardation Students',
  HOUR: 'hour',
  MIN: 'minute',
  AM: 'AM',
  PM: 'PM',
  studentsCount: 'Students Count',
  AMPM: 'am/pm',
  SUB_QUESTION_NUM: 'Sub-Question Number',
  NEXT_SUB_QUESTION: 'Next Sub-Question',
  PERVIOUS_SUB_QUESTION: 'Pervious Sub-Question',
  CHANGE_STAGE: 'Change Educational Level',
  VERIFY: 'Verify',
  CAPTCHA_KEY: 'Confirmation Code',
  NO_STAGE: 'There is no stage',
  MINOR: 'Minor edit, keep the current version of the question',
  MAJOR: 'Major edit, upgrade version of question to new version',
  SYNC_STAFF_COURSES: 'Update Staff Courses',
  NO_SYNC_STAFF_COURSES_RIGHT: 'You do not have permission to update staff courses',
  ENTER_VALID_EGYPTIAN_NATIONAL_ID: 'Please enter valid national id',
  STAFF_NAME: 'Name',
  VIEW_QUESTION_REPORTS: 'View Question Reports',
  VIEW_QUESTION_DETAILS: 'View Question Details',
  QUESTION_REPORTS: 'Question Reports',
  IBN_HIATHEM_COURSE_IDS: 'Ibn haitham ids for not found courses',
  USER_OUT_SIDE_SYSTEM_ALERT: 'You can\'t update courses for a user added from outside the system',
  BOOKED_TIME_SLOTS: 'Booked time slots',
  SUB_QUESTION_NEED_TO_FIX: 'One of the sub-questions needs to fix',
  AUTO_SELECT_DATA: 'Data for automatic selection of questions',
  PDF: 'PDF File',
  DOCX: 'DOCX File',
  TIME_ZONE_ALERT: 'Make sure of correctly setting your device time zone as it affects reserved exam time. Make sure to revise exam time after save. ',
  EXPORT_FOR_BSHEET: 'Export to electronic correction system capability',
  BSHEET_QUESTION_LANG_TIP: 'Please choose question language to enable this option',
};

export default enTranslate;
