























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Ilo, HeaderILos } from '../types/ilos.type';

@Component({
  props: {
    // selectedIlos: {
    //   type: Object,
    //   default() {
    //     const ilosObject: HeaderILos = {
    //       "1.1.": [],
    //       "1.2.": [],
    //       "1.3.": [],
    //       "1.4.": [],
    //     };
    //     return ilosObject;
    //   },
    // },ئ
    // ilos: {
    //   type: Object,
    //   default() {
    //     const ilosObject: HeaderILos = {
    //       "1.1.": [],
    //       "1.2.": [],
    //       "1.3.": [],
    //       "1.4.": [],
    //     };
    //     return ilosObject;
    //   },
    // },
  },
})
export default class IlosAddComponent extends Vue {
  error = false;

  @Prop({ default: 0 }) readonly ILOIndex!: number;

  @Prop({
    type: Object,
    default: () => ({
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    }),
  })
  readonly ilos!: HeaderILos;

  @Prop({
    type: Object,
    default: () => ({
      '1.1.': [],
      '1.2.': [],
      '1.3.': [],
      '1.4.': [],
    }),
  })
  readonly selectedIlos!: HeaderILos;

  selectedIlosIds = {};

  unRemovableIlosIds = {};

  unRemovableSubIlosIds = {};

  get subjectIlos() {
    return this.ilos;
  }

  get isNoIlos() {
    let sum = 0;
    Object.keys(this.subjectIlos).forEach((header) => {
      const headerIlos = this.subjectIlos[header];
      if (headerIlos && headerIlos.length) {
        sum += headerIlos.length;
      } else {
        this.$emit('resetSelectedIlosHeader');
      }
    });
    return Object.keys(this.subjectIlos).length === 0 || sum === 0;
  }

  mounted() {
    this.updateSelectedIlosChecks();
  }

  updateSelectedIlosChecks() {
    this.selectedIlosIds = Object.keys(this.selectedIlos).reduce((acc, key) => {
      this.selectedIlos[key].forEach((iloData) => {
        if (iloData.iloId) {
          acc[iloData.iloId.toString()] = true;
        }
        if (iloData.subIloId) {
          acc[iloData.subIloId.toString()] = true;
        }
      });
      return acc;
    }, {});

    Object.keys(this.selectedIlos).forEach((key) => {
      this.selectedIlos[key].forEach((iloData) => {
        if (iloData.unRemovable) {
          this.unRemovableIlosIds[iloData.iloId.toString()] = true;
        }
        if (iloData.unRemovableSub) {
          this.unRemovableSubIlosIds[iloData.subIloId.toString()] = true;
        }
      });
    });
  }

  updateChecked(item: Ilo, value: boolean) {
    this.error = false;
    if (value) {
      this.addItem(item);
    } else {
      this.removeItem(item);
    }
    this.updateSelectedIlosChecks();
    this.$emit('updateSubjectIlos', {
      selectedIlos: this.selectedIlos,
      ILOIndex: this.ILOIndex,
    });
  }

  headerIlosChecked(item: Ilo[]) {
    const checkArray: boolean[] = [];
    item.forEach((ilo) => {
      checkArray.push(this.checkIloOrSub(ilo));
    });
    return !checkArray.includes(false);
  }

  headerIlosUnRemoveable(item: Ilo[], headerId: string) {
    const unRemovableArray: boolean[] = [];
    item.forEach((ilo) => {
      if (ilo && ilo.iloId && ilo.headerId === headerId) {
        unRemovableArray.push(this.unRemovableIlosIds[ilo.iloId.toString()]);
      }
    });
    return unRemovableArray.includes(true);
  }

  // iloChecked(item: Ilo) {
  //   let check = false;
  //   if (this.selectedIlos[item.headerId].length) {
  //     check = this.checkIloOrSub(item);
  //   }
  //   return check;
  // }

  checkIloOrSub(item: Ilo) {
    let check = false;
    if (item.subIlos && item.subIlos.length) {
      const subIlos = this.selectedIlos[item.headerId].filter(
        (ilo: Ilo) => ilo.iloId?.toString() === item.iloId?.toString(),
      );
      if (subIlos.length === item.subIlos.length) {
        check = true;
      }
    } else {
      check = this.itemChecked(item);
    }
    return check;
  }

  itemChecked(item: Ilo) {
    // check ilo or subIlo present in the subjectIlos
    // ilo with no subIlos its subIloId is null
    const itemFound = this.selectedIlos[item.headerId].find(
      (ilo: Ilo) => ilo.iloId?.toString() === item.iloId?.toString()
        && ilo.subIloId?.toString() === item.subIloId?.toString(),
    );
    if (!itemFound) {
      return false;
    }
    return true;
  }

  getUnselectedSubIlos(subIlos: Ilo[], headerId: string) {
    return subIlos.filter(
      (sub) => this.selectedIlos[headerId].findIndex(
        (c: Ilo) => sub.subIloId?.toString() === c.subIloId?.toString(),
      ) === -1,
    );
  }

  addIloOrSub(item: Ilo) {
    const { headerId } = item;
    if (item.subIlos && item.subIlos.length) {
      const unSelectedSubs = this.getUnselectedSubIlos(item.subIlos, headerId);
      // push subilos array to the selected ilos array if it has subilos
      this.selectedIlos[headerId].push(...unSelectedSubs);
    } else {
      const selectedItem = this.selectedIlos[headerId].find(
        (c: Ilo) => c.iloId?.toString() === item.iloId?.toString()
          && c.subIloId?.toString() === item.subIloId?.toString(),
      );
      if (!selectedItem) {
        this.selectedIlos[headerId].push(item);
      }
      // push single iloto the selected ilos array
    }
  }

  addItem(item: Ilo | Ilo[]) {
    if (Array.isArray(item)) {
      // push headerIlo ilos array to the selected ilos array
      item.forEach((ilo) => {
        this.addIloOrSub(ilo);
      });
    } else {
      this.addIloOrSub(item);
    }
  }

  removeIloOrSub(item: Ilo) {
    const headerId: string = item.headerId || '';
    if (item.subIlos && item.subIlos.length) {
      this.selectedIlos[headerId] = this.selectedIlos[headerId].filter(
        (ilo: Ilo) => !(ilo.iloId?.toString() === item.iloId?.toString()),
      );
    } else {
      this.selectedIlos[headerId] = this.selectedIlos[headerId].filter(
        (ilo: Ilo) => !(
            ilo.iloId?.toString() === item.iloId?.toString()
            && ilo.subIloId?.toString() === item.subIloId?.toString()
        ),
      );
    }
  }

  removeItem(item: Ilo) {
    if (Array.isArray(item)) {
      const { headerId } = item[0];
      this.selectedIlos[headerId] = this.selectedIlos[headerId].filter(
        (ilo: Ilo) => item.findIndex(
          (c) => c.iloId?.toString() === ilo.iloId?.toString(),
        ) === -1,
      );
    } else {
      this.removeIloOrSub(item);
    }
  }

  // get selected() {
  //   if (this.questionIlos.length) {
  //     const headerId = this.questionIlos[0].header_id;
  //     //check duplicate headers
  //     if (
  //       this.questionIlos.filter((ilo) => ilo.header_id == headerId).length ==
  //       this.questionIlos.length
  //     ) {
  //       this.headerIlos = this.subjectIlos[headerId];
  //       return headerId;
  //     } else {
  //       this.$emit("updateIlos", []);
  //     }
  //   }
  // }
}
