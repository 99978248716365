
































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import servertranslate from '@/filters/server-translate';
import DialogComponent from '@/components/dialog.component.vue';
import { CourseBasic, CourseDataResponse } from '../types/course.type';
import CoursesProvider from '../providers/courses.provider';

@Component({
  components: {
    DialogComponent,
  },
})
export default class SelectedCourse extends Vue {
  @Prop(String) readonly appId!: string;

  @Prop(String) readonly BackUrl!: string;

  @Prop(Boolean) readonly goBackWithConfirmMsg!: boolean;

  course: CourseBasic = {};

  servertranslate = servertranslate;

  showBackConfirm = false;

  mounted() {
    this.fetchCourse();
  }

  async fetchCourse() {
    const {
      course,
    }: CourseDataResponse = await CoursesProvider.getCourseView(
      this.appId,
      this.courseId,
      { onlyInfo: true },
    );
    this.course = course;
    this.$emit('update:course', { ...this.course });
  }

  get courseId() {
    return this.$route.params.courseId;
  }

  get courseName() {
    return this.course.courseName;
  }

  navigateUrl() {
    this.$router.push({ path: this.BackUrl });
  }

  goToNavigateUrl() {
    if (this.BackUrl) {
      if (this.goBackWithConfirmMsg) {
        this.showBackConfirm = true;
      } else {
        this.navigateUrl();
      }
    }
  }
}
