import SubjectCourseList from '@/components/subjects/CourseList.component.vue';
import SubjectList from '@/components/subjects/SubjectList.component.vue';
import SubjectEditAdd from '@/components/subjects/SubjectEdit.component.vue';
import { SUBJECT_ROUTE_NAME } from '@/enums';

export const SUBJECT_ROUTES = [
  {
    name: SUBJECT_ROUTE_NAME.COURSE_LIST,
    path: 'courses-list',
    component: SubjectCourseList,
  }, {
    name: SUBJECT_ROUTE_NAME.LIST,
    path: ':courseId/',
    component: SubjectList,
  }, {
    name: SUBJECT_ROUTE_NAME.EDIT,
    path: ':courseId/edit/:subjectId',
    component: SubjectEditAdd,
  },
  {
    name: SUBJECT_ROUTE_NAME.ADD,
    path: ':courseId/add',
    component: SubjectEditAdd,
  },
];

export const SUBJECTS_ROUTES_SCOPES_RIGHTS = {
  'subject-courses-list': '9.2.',
  'subject-list': '9.2.',
  'subject-add': '9.3.',
  'subject-edit': '9.4.',
};
