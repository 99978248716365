export const REPORTS_ROUTES = [
  {
    path: 'questions-status-stats',
    name: 'questions-status-stats-report',
    component: () => import('@/components/reports/questionsStatusStatsReport.component.vue'),
  },
  {
    path: 'course-questions-statistics/courses-list',
    name: 'course-questions-statistic-courses-list',
    component: () => import('@/components/reports/CourseList.component.vue'),
  },
  {
    path: 'course-questions-statistics/:courseId',
    name: 'course-questions-statistics-report',
    component: () => import('@/components/reports/courseQuestionsStatistics.component.vue'),
  },
  {
    path: 'exams-creators',
    name: 'exams-creators-report',
    component: () => import('@/components/reports/examsCreatorsReport.component.vue'),
  },
  {
    path: 'exams-creators/:courseId/:creatorId',
    name: 'exams-creators-report',
    component: () => import('@/components/reports/examsCreatorsReport.component.vue'),
  },
];

export const REPORTS_ROUTES_SCOPES_RIGHTS = {
  'questions-status-stats-report': '9.2.',
  'course-questions-statistics': '9.2.',
  'exams-creators-report': '9.2.',
};
