



















































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
/* eslint-disable import/no-cycle */
import * as easings from 'vuetify/es5/services/goto/easing-patterns';
import { GoToOptions } from 'vuetify/types/services/goto.d';
import SubjectProvider from '@/providers/subject.provider';
import { Subject, SubjectListResponse } from '@/types/subject.types';
import IlosComponet from '@/shared/ilos-view-component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import DialogComponent from '@/components/dialog.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import { Lang } from '../../types/general.type';

import { SUBJECT_ROUTE_NAME, Rights, APPIDS } from '../../enums';
import SelectedCourse from '../SelectedCourse.component.vue';

@Component({
  components: {
    Ilos: IlosComponet,
    SelectedCourse,
    ScopeComponent,
    DialogComponent,
  },
})
export default class SubjectsList extends mixins(Toaster) {
  name = 'SubjectsList';

  appId: string = APPIDS.SUBJECTS;

  courseId: string = this.$route?.params.courseId;

  subjects: Array<Subject> = [];

  search = '';

  currentPanel = -1;

  rights: Array<string> = [];

  loading = false;

  error = '';

  scrollingOptions = {
    duration: 300,
    easing: 'easeInOutCubic',
    easings: Object.keys(easings),
  };

  toBeDeletedSubject = '';

  showDeleteDialog = false;

  loadingDelete = false;

  isFlexibleILOs = false;

  get filteredSubjects(): Array<Subject> {
    if (this.search) {
      const re = new RegExp(this.search);
      return this.subjects.filter((subject: Subject) => subject?.name?.match(re));
    }
    return this.subjects;
  }

  get haveEditPermisions() {
    return this.rights.indexOf(Rights.edit) !== -1;
  }

  get haveAddPermisions() {
    return this.rights.indexOf(Rights.add) !== -1;
  }

  get haveDeletePermisions() {
    return this.rights.indexOf(Rights.delete) !== -1;
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
    await this.loadCourseSubjects();
    if (this.$route.hash.split('#')[1]) {
      this.setCurrentPanel(this.$route.hash.split('#')[1]);
    } else if (this.subjects.length) {
      this.openPanel(0, this.subjects[0].id);
      this.setCurrentPanel(this.subjects[0].id);
    }
  }

  async loadCourseSubjects() {
    try {
      this.loading = true;
      const response: SubjectListResponse = await SubjectProvider.getCourseSubjects(
        this.courseId,
      );
      this.loading = false;
      this.subjects = response.subjects;
      this.isFlexibleILOs = !!response.isFlexibleILOs;
      this.rights = response.rights;
    } catch (err) {
      this.loading = false;
      this.error = err;
      // this.errorToaster(err.en);
    }
  }

  setCurrentPanel(subjectId: string | undefined) {
    this.$vuetify.goTo(
      `#subject-${subjectId}`,
      this.scrollingOptions as GoToOptions,
    );
    const index = this.subjects.findIndex(
      (subject) => subject.id === subjectId,
    );
    if (index !== -1) {
      this.currentPanel = index;
    }
  }

  openPanel(index: number, subjectId: string | undefined) {
    if (this.currentPanel !== index) {
      this.$router.replace(`#${subjectId}`);
      setTimeout(() => {
        this.$vuetify.goTo(
          `#subject-${subjectId}`,
          this.scrollingOptions as GoToOptions,
        );
      }, 300);
    }
  }

  openEditPage(subjectId: string) {
    this.$router.push({
      name: SUBJECT_ROUTE_NAME.EDIT,
      params: {
        subjectId,
      },
    });
  }

  showDeleteConfirm(subjectId: string) {
    this.toBeDeletedSubject = subjectId;
    this.showDeleteDialog = true;
  }

  async deleteSubject() {
    try {
      this.loadingDelete = true;
      await SubjectProvider.deleteSubject(this.appId, this.toBeDeletedSubject, this.courseId);
      this.loadingDelete = false;
      this.toBeDeletedSubject = '';
      this.showDeleteDialog = false;
      this.$router.replace(this.$route.path);
      this.$router.go(0);
    } catch (err) {
      this.loadingDelete = false;
      this.showDeleteDialog = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  openAddSubject() {
    this.$router.push({
      name: SUBJECT_ROUTE_NAME.ADD,
    });
  }

  goCoursesList() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: SUBJECT_ROUTE_NAME.COURSE_LIST, query: courseFilters });
  }
}
