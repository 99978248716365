const lang = {
  state: {
    lang: 'ar',
  },
  mutations: {
    switchLang(state) {
      if (state.lang === 'ar') {
        state.lang = 'en';
      } else {
        state.lang = 'ar';
      }
    },

  },
};

export default lang;
