/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AddCoursesFiltersResponse,
  AddSystemCourseResponse,
  ImportFromServiceResponse,
  ImportFromExcelResponse,
  CoursesListResponse,
  CourseDataResponse,
  CourseIlo,
  CourseEditResponse,
  CourseFiltersInputs,
  CourseListProvider,
  ImportInstructorResult,
  Rule,
  BasicResponse,
  IlosLangResponse,
  ElectronicExamsReportResponse,
  ElectronicExamLinkResponse,
} from '@/types/course.type';
import {
  SubjectsDataResponse,
} from '@/types/subject.types';
import {
  GeneralResponse, Lang,
} from '@/types/general.type';
import provider from './main.provider';

class CoursesProvider implements CourseListProvider {
  id = 0;

  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const result: CoursesListResponse = await provider.get(`/api/courses/${appId}/getList`, {
      params: query,
    });
    return result;
  }

  async getAddFilters(appId: string) {
    const result: AddCoursesFiltersResponse = await provider.get(`/api/courses/${appId}/getAddFilters`);
    return result;
  }

  async getCourseView(appId: string, courseId: string, query = {}): Promise<CourseDataResponse> {
    const result: CourseDataResponse = await provider.get(`/api/courses/${appId}/${courseId}`, {
      params: query,
    });
    if (!result.course.flexibleIlos) {
      result.courseIlos = result.courseIlos ? this.mapIlos(result.courseIlos) : [];
    }
    return result;
  }

  async getCourseEdit(appId: string, courseId: number): Promise<CourseEditResponse> {
    const result: CourseEditResponse = await provider.get(`/api/courses/${appId}/edit/${courseId}`);
    return result;
  }

  async getFoundation(
    appId: string,
    scopeId: string,
  ): Promise<{ isSCU: boolean }> {
    const result: { isSCU: boolean } = await provider.get(
      `/api/courses/${appId}/getFoundation${scopeId ? `?scopeId=${scopeId}` : ''}`,
    );
    return result;
  }

  async addCourse(appId: string, course: object) {
    const result: AddSystemCourseResponse = await provider.post(`/api/courses/${appId}/add`, course);
    return result;
  }

  async editCourse(appId: string, courseId: number, course: object) {
    const result: AddSystemCourseResponse = await provider.put(`/api/courses/${appId}/edit/${courseId}`, course);
    return result;
  }

  async getCourseElectronicExams(appId: string, courseId: string): Promise<ElectronicExamsReportResponse> {
    const result: ElectronicExamsReportResponse = await provider.get(`/api/courses/${appId}/${courseId}/getCourseElectronicExams`);
    return result;
  }

  async getExamRedirect(appId: string, courseId: string, examId: string): Promise<ElectronicExamLinkResponse> {
    const result: ElectronicExamLinkResponse = await provider.get(`/api/courses/${appId}/${courseId}/getExamRedirect/${examId}`);
    return result;
  }

  async getCourseSubjects(appId: string, courseId: string) {
    const result: SubjectsDataResponse = await provider.get(`/api/courses/${appId}/getCourseSubjects/${courseId}`);
    return result;
  }

  async courseActivation(appId: string, courseId: string, active: boolean,
    subjects: string[], activateSubject: boolean) {
    const result: GeneralResponse = await provider.put(`/api/courses/${appId}/courseActivation/${courseId}/${active}`, { subjects, activateSubject });
    return result;
  }

  async importFromService(appId: string, reqData) {
    const result: ImportFromServiceResponse = await provider.post(`/api/courses/${appId}/serviceImport`, reqData);
    return result;
  }

  async importFromExcel(appId: string, reqData) {
    const result: ImportFromExcelResponse = await provider.post(`/api/courses/${appId}/fileImport`, reqData);
    return result;
  }

  async addInstructors(appId: string, scope: string): Promise<ImportInstructorResult> {
    const result: ImportInstructorResult = await provider.put(`/api/courses/${appId}/importInstructors`, { scope });
    return result;
  }

  async addInstructorsRule(appId: string, scope: string): Promise<Rule> {
    const result: Rule = await provider.get(`/api/courses/${appId}/importRole`, {
      params: { scope },
    });
    return result;
  }

  async getCourseIlosLang(appId: string, courseId: string): Promise<IlosLangResponse> {
    const result: IlosLangResponse = await provider.get(
      `/api/courses/${appId}/${courseId}/getCourseIlosLang`,
    );
    return result;
  }

  async importCourseIlos(appId: string, courseId: string, reqData): Promise<BasicResponse> {
    const result: BasicResponse = await provider.put(
      `/api/courses/${appId}/${courseId}/importCourseIlos`,
      reqData,
    );
    return result;
  }

  async resetIlos(appId: string, courseId: string): Promise<Lang> {
    const result: Lang = await provider.delete(
      `/api/courses/${appId}/${courseId}/courseResetIlos`,
    );
    return result;
  }

  async deleteCourse(appId: string, courseId: string): Promise<GeneralResponse> {
    const result: GeneralResponse = await provider.delete(
      `/api/courses/${appId}/${courseId}`,
    );
    return result;
  }

  mapIlos(courseIlos) {
    this.id = 0;
    return courseIlos.reduce((acc, ilo: CourseIlo) => {
      if (ilo.iloId) {
        const headerIndex = acc.findIndex(
          (c) => c.headerId === ilo.headerId,
        );
        if (headerIndex === -1) {
          acc.push(this.addHeaderIlo(ilo));
        } else {
          const iloIndex = acc[headerIndex].children.findIndex(
            (c) => c.iloId === ilo.iloId,
          );
          if (iloIndex === -1) {
            acc[headerIndex].children.push(this.addIlo(ilo));
          } else {
            acc[headerIndex].children[iloIndex].children.push(
              this.addSubIlo(ilo),
            );
          }
        }
      }
      return acc;
    }, []);
  }

  addHeaderIlo(ilo) {
    this.id += 1;
    return {
      id: this.id,
      headerId: ilo.headerId,
      name: ilo.headerName,
      code: ilo.headerCode,
      children: [this.addIlo(ilo)],
    };
  }

  addIlo(ilo) {
    this.id += 1;
    return {
      id: this.id,
      iloId: ilo.iloId,
      name: ilo.iloDescription,
      code: ilo.iloCode,
      children: ilo.subIloId ? [this.addSubIlo(ilo)] : [],
    };
  }

  addSubIlo(ilo) {
    this.id += 1;
    return {
      id: this.id,
      subIloId: ilo.subIloId,
      code: ilo.subIloCode,
      name: ilo.subIloDescription,
    };
  }
}

export default new CoursesProvider();
