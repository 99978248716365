const filters = {
  state: {
    appFilters: {},
    courseFilters: {},
  },
  mutations: {
    AppFilters(state, newfilters) {
      state.appFilters = newfilters;
    },
    CourseFilters(state, newfilters) {
      state.courseFilters = newfilters;
    },
    resetAppFilters(state) {
      state.appFilters = {};
    },
    resetCourseFilters(state) {
      state.courseFilters = {};
    },
    resetAllFilters(state) {
      state.appFilters = {};
      state.courseFilters = {};
    },
  },
};

export default filters;
