import { QuestionsTypesEnum } from '../types/questions.type';

export const QUESION_TYPES: QuestionsTypesEnum = {
  ESSAY: {
    id: '3.1.',
    label: 'labels.questionTypes.essay',
    defaultText: { ar: 'مقالي', en: 'Essay' },
  },
  MCQ: {
    id: '3.2.',
    label: 'labels.questionTypes.mcq',
    defaultText: { ar: 'اختر من متعدد', en: 'MCQ' },
  },
  MMCQ: {
    id: '3.3.',
    label: 'labels.questionTypes.mmcq',
    defaultText: { ar: 'متعدد الاختيارات', en: 'MMCQ' },
  },
  T_F: {
    id: '3.4.',
    label: 'labels.questionTypes.TF',
    defaultText: { ar: 'صح ام خطأ', en: 'T & F' },
  },
  CASE_STUDY: {
    id: '3.5.',
    label: 'labels.questionTypes.CS',
    defaultText: { ar: 'دراسة حالة', en: 'Case Study' },
  },
  MATCHING: {
    id: '3.6.',
    label: 'labels.questionTypes.MATCH',
    defaultText: { ar: 'سؤال المطابقة', en: 'Matching Question' },
  },
  '3.1.': function _() {
    return this.ESSAY;
  },
  '3.2.': function _() {
    return this.MCQ;
  },
  '3.3.': function _() {
    return this.MMCQ;
  },
  '3.4.': function _() {
    return this.T_F;
  },
  '3.5.': function _() {
    return this.CASE_STUDY;
  },
  '3.6.': function _() {
    return this.MATCHING;
  },
};

export const QUESTIONS_STATUS = {
  INCOMPLETE: '5.1.',
  PENDING_REVIEW: '5.2.',
  APPROVED: '5.3.',
  REJECTED: '5.4.',
  DELETED: '5.5.',
  COMPLETE: '5.6.',
};

export const QUESTIONS_IMPORT_TYPES_CODES = {
  MOODEL: '1',
  QMANS_XML: '2',
  QMANS_DOC: '3',
  QMANS_EXCEL: '4',
  MOLEX_EXCEL: '5',
  MOLEX_JSON: '6',
};

export const STATUS_CODES = {
  SUCCESS: 200,
  SERVER_ERROR: 500,
  UNPROCESSABLE_ENTITY: 422,
  FORBIDDEN: 403,
  UNSUPPORTED_MEDIA_TYPE: 415,
  NOT_FOUND: 404,
  DATABASE: {
    DOCUMENT_LIMIT_EXCEEDED: 17419,
  },
};

export const Rights = {
  view: '9.2.',
  add: '9.3.',
  edit: '9.4.',
  delete: '9.5.',
  close: '9.6.',
  open: '9.7.',
  editAll: '9.14.',
  deleteAll: '9.15.',
};

export const APPIDS = {
  COURSES: '165.1.1.',
  SUBJECTS: '165.1.2.',
  CREATE_QUESTIONS: '165.2.1.',
  REVIEW_QUESTIONS: '165.2.2.',
  QUESTIONS_STATUS_REPORT: '165.4.1.',
  EXAM_GENERATION: '165.7.1.',
  RESERVE_EXAM: '165.7.2.',
  COURSE_QUESTIONS_STASTICS: '165.4.2.',
  COURSE_ILOS: '165.1.3.',
  COURSES_SYSTEM: '165.1.',
  QUESTIONS_SYSTEM: '165.2.',
  REPORTS_SYSTEM: '165.4.',
  EXAMS_SYSTEM: '165.7.',
  EXAMS_CREATORS_REPORT: '165.4.3.',
};

export const SUBJECT_ROUTE_NAME = {
  COURSE_LIST: 'subject-courses-list',
  LIST: 'subject-list',
  EDIT: 'subject-edit',
  ADD: 'subject-add',
};

export const COURSES_ROUTE_NAME = {
  LIST: 'course-list',
  EDIT: 'course-edit',
  ADD: 'course-add',
  VIEW: 'course-view',
  INSTRUCTORS: 'course-instructors',

};
export const COURSES_ILOS_ROUTE_NAME = {
  COURSES_LIST: 'course-ilos-course-list',
  COURSES_ILOS_VIEW: 'course-ilos-view',
};
export const EXAM_GENERATION_ROUTE_NAME = {
  COURSES_LIST: 'exam-generation-courses-list',
  EXAMS_LIST: 'exams-list',
  EXAM_DETAILS: 'exam-details',
  ADD_GENERATED_EXAM: 'add_generated_exam',
  QUESTION_DETAILS: 'question-details',
};
export const RESERVE_EXAM_ROUTE_NAME = {
  COURSES_LIST: 'reserve-exam-courses-list',
  RESERVE_EXAMS_LIST: 'reserve-exam-list',
  RESERVE_EXAM_DETAILS: 'reserve-exam-details',
  ADD_RESERVATION: 'reserve-exam-add',

};
export const MODEL_GENERATION_ROUTE_NAME = {
  MODEL_DETAILS: 'model-details',
};
export const QUESTIONS_ROUTES_NAMES = {
  COURSES_LIST: 'questions-courses-list',
  QUESTIONS_LIST: 'questions-list',
  ADD: 'question-add',
  EDIT: 'question-edit',
  COPY: 'question-copy',
  VIEW: 'question-details',
  QUESTION_IMPORT: 'questions-import',
  EDIT_CASE_STUDY: 'editCaseStudy',
  COPY_CASE_STUDY: 'copyCaseStudy',
};

export const REVIEW_QUESTIONS_ROUTES_NAMES = {
  COURSES_LIST: 'review-questions-courses-list',
  LIST: 'review-questions-list',
};

export const COURSE_AVTIVE_STATUS = {
  ACTIVE: '1',
  IN_ACTIVE: '0',
  ANY: '',
};

export const FILE_VALIDATION_ERRORS = {
  TYPE: 'type',
  SIZE: 'size',
};

export const EXCEL_FILE_FORMATE = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const QUESTIONS_STATUS_REPORT = {
  COURSE_STATUS: {
    ALL: '1',
    HAS_QUESTION: '2',
    HAS_NO_QUESTION: '3',
  },
  DISTRIBUTION: {
    BY_FACULTY: '1',
    BY_DEPARTMENT: '2',
    BY_GRADE: '3',
  },
};

export const SUPPORTED_LANGS = {
  AR: '4.1.',
  EN: '4.2.',
};

export const EXPORT_EXAM = {
  BASIC: '20.',
  MODELS_ADD: '20.1.',
  ELECTRONIC_CORRECTION: '20.2.',
  ELECTRONIC_EXAM: '20.3.',
  PUBLISH_FOR_ALL: '20.4.',
  NO_MODEL: '20.5.',
};

export const MODELS_MATCH = {
  PERFECT_MATCH: '22.1.',
  NOT_MATCHING: '22.2.',
};

export const COURSE_QUESTIONS_STASTICS = {
  COURSES_LIST: 'course-questions-statistic-courses-list',
  VIEW: 'course-questions-statistics-report',
};
export const COURSE_TYPE = {
  BASIC: '15.',
  CREDIT_HOURS: '15.1.',
  ACADIMIC_YEAR: '15.2.',
  EXTERNAL_COURSE: '15.3.',
  PGS_ACADEMIC: '15.4.',
  PGS_CREDIT: '15.5.',
  EXTERNAL_COURSE_PGS: '15.6.',
};

export const EXAM_CENTER_REQUEST_STATUS = {
  BASIC: '34.',
  PENDING: '34.1.',
  ACCEPTED: '34.2.',
  REJECTED: '34.3.',
};

export const QMANS_MAIN_APPS_IDS = [
  APPIDS.COURSES_SYSTEM,
  APPIDS.QUESTIONS_SYSTEM,
  APPIDS.REPORTS_SYSTEM,
  APPIDS.EXAMS_SYSTEM,
];

export const TABLE_HEADER_CLASS = 'grey secondary--text pa-3 qb-table__header';
export const FLOATING_DIGITS = 2;

export const ANSWER_CHOICES = [
  {
    ar: 'أ',
    en: 'a',
  },
  {
    ar: 'ب',
    en: 'b',
  },
  {
    ar: 'ج',
    en: 'c',
  },
  {
    ar: 'د',
    en: 'd',
  },
  {
    ar: 'ه',
    en: 'e',
  },
  {
    ar: 'و',
    en: 'f',
  },
  {
    ar: 'ز',
    en: 'g',
  },
  {
    ar: 'ح',
    en: 'h',
  },
  {
    ar: 'ط',
    en: 'i',
  },
  {
    ar: 'ي',
    en: 'j',
  },
  {
    ar: 'ك',
    en: 'k',
  },
  {
    ar: 'ل',
    en: 'l',
  },
  {
    ar: 'م',
    en: 'm',
  },
  {
    ar: 'ن',
    en: 'n',
  },
  {
    ar: 'س',
    en: 'o',
  },
  {
    ar: 'ع',
    en: 'p',
  },
  {
    ar: 'ف',
    en: 'q',
  },
  {
    ar: 'ص',
    en: 'r',
  },
  {
    ar: 'ق',
    en: 's',
  },
  {
    ar: 'ر',
    en: 't',
  },
];

export const EDIT_QUESTION_TYPE = {
  MINOR: 0,
  MAJOR: 1,
};

export const USER_TYPE = {
  FAROUK_SYSTEM: '10.1.',
  FARABI_SYSTEM: '10.2.',
  OUTSIDE_OF_THE_SYSTEM: '10.3.',
  STUDENTS_AFFAIRS_SYSTEM: '10.4.',
  MYU_USER: '10.5.',
};

export const NEED_TO_FIX_FILTERS = {
  DIFFICULTY: 'difficulty',
  TIME: 'time',
  TIME_AND_DIFFICULTY: 'both',
};

export const ILOS_OPTIONS_IDS = {
  CUSTOM: 'custom',
  FIXED: 'fixed',
};

export const VALID_DIFFICULTY_RANGE = 0.15;
export const VALID_TIME_RANGE = 0.25;

export const EGYPTIAN_NATIONAL_ID_REGEX = /^(2|3)[0-9][1-9][0-1][1-9][0-3][1-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d$/;

export const NORMALIZING_ARABIC_CHARS_MAPPING = {
  آ: 'ا',
  أ: 'ا',
  إ: 'ا',
  ة: 'ه',
  ى: 'ي',
};
