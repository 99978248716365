const navigatQuestionsListId = {
  state: {
    questionsIdLists: {},
  },
  mutations: {
    mapQuestionList(state, payload) {
      state.questionsIdLists[String(payload.appId)] = payload.questions?.map((ele) => String(ele.questionId)) || [];
    },
    clearListForApp(state, appId) {
      state.questionsIdLists[String(appId)] = [];
    },
  },

};

export default navigatQuestionsListId;
