const auth = {
  state: {
    token: null,
    username: null,
    userImage: null,
    canSyncCourses: false,
    resetPassword: false,
    resetMessage: null,
    sessionDuration: null,
    sessionExpiry: null,
  },

  mutations: {
    setUserAuth(
      state: { token; username; resetPassword; resetMessage; sessionDuration },
      payload: {
        token;
        username?: string;
        resetPassword?: boolean;
        resetMessage?: { ar: string; en?: string };
        sessionDuration?: number;
      },
    ) {
      state.token = payload.token;
      state.username = payload.username;
      state.resetPassword = payload.resetPassword;
      state.resetMessage = payload.resetMessage;
      state.sessionDuration = payload.sessionDuration;
    },
    updateToken(state, payload) {
      state.token = payload.token;
      if (state.sessionDuration) {
        const expiryDate = new Date(Date.now());
        expiryDate.setSeconds(expiryDate.getSeconds() + state.sessionDuration);
        state.sessionExpiry = expiryDate;
      }
    },
    updateUserImage(state, payload) {
      state.userImage = payload.userImage;
    },
    updateUserName(state, userName) {
      state.username = userName;
    },
    setCanSyncCourses(state, canSyncCourses) {
      state.canSyncCourses = canSyncCourses;
    },
  },
};

export default auth;
