import { Rights } from '@/enums';

export const EXAM_GENERATION_ROUTES = [

  {
    path: 'courses-list',
    name: 'exam-generation-courses-list',
    component: () => import('@/components/exam-generation/coursesList.component.vue'),

  },
  {
    path: 'add_generated_exam/:courseId/:examId?',
    name: 'add_generated_exam',

    component: () => import('@/components/exam-generation/ExamAdd.component.vue'),
  },
  {
    path: 'show_model/:courseId/:examId/:modelNumber',
    name: 'model-details',
    component: () => import('@/components/exam-generation/ShowModel.component.vue'),
  },
  {
    path: ':courseId/exams-list',
    name: 'exams-list',
    component: () => import('@/components/exam-generation/examsList.component.vue'),

  }, {
    path: ':courseId/:examId/models/add',
    name: 'add-model',
    component: () => import('@/components/exam-generation/modelsAddEdit.component.vue'),
  },
  {
    path: ':courseId/:examId/models/edit/:modelNo',
    name: 'edit-model',
    component: () => import('@/components/exam-generation/modelsAddEdit.component.vue'),
  },
  {
    path: ':courseId/:examId/models/copy/:modelNo',
    name: 'copy-model',
    component: () => import('@/components/exam-generation/modelsAddEdit.component.vue'),
  },

  {
    path: ':courseId/:examId',
    name: 'exam-details',
    component: () => import('@/components/exam-generation/examDetails.component.vue'),
  },
];

export const EXAM_GENERATION_ROUTES_SCOPES_RIGHTS = {
  'exam-generation-courses-list': Rights.view,
  'exams-list': Rights.view,
  'exams-details': Rights.view,

};
