import Component, { mixins } from 'vue-class-component';
import {
  Watch, PropSync, Prop, Inject,
} from 'vue-property-decorator';

import {
  Route, Query, SortData, CourseBasic, CoursesListResponse,
  CourseFilters,
  CourseListProvider,
} from '@/types/course.type';
import { Lang } from '@/types/general.type';
import Toaster from './toaster.mixin';

@Component
export default class CourseListMixin extends mixins(Toaster) {
  @PropSync('filters', { default: () => ({ courseTypes: [] }) }) readonly filtersOptions!: CourseFilters;

  @Prop() showAction!: boolean;

  @Prop() showAddBtn!: boolean;

  @Prop() appId!: string;

  @Prop() name!: string;

  @Inject() readonly Provider!: CourseListProvider;

  userRights: string[] | undefined = [];

  headerClass = 'grey darken-4 secondary--text pa-3 qb-table__header';

  loading = false;

  queries: Query = {};

  loadingFilters = false;

  courseList: CourseBasic[] = [];

  totalItems = 0;

  limit = 10;

  page = 1

  tablePage = 1;

  tableLimit = 10;

  filtersInputs: {
    courseName?: string;
    stdPlan?: string;
    IHId?: string;
    courseType?: string;
    activeStatus?: string;
    validFilters?: boolean;
  } = {
    courseName: '',
    stdPlan: '',
    IHId: '',
    courseType: '',
    activeStatus: '',
    validFilters: false,
  }

  currentSelectedScope!: {
    selectedScopeId: string;
  };

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  limitOption = [5, 10, 20, 50];

  sortKeys: string[] = [];

  sortDesc: boolean[] = [];

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  created() {
    this.setQueries();
  }

  mounted() {
    this.$store.commit('resetCourse');
    this.$store.commit('resetAppFilters');
    this.fetchFiltersData();
  }

  @Watch('$store.state.lang.lang', { deep: true })
  onstoreChanged(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.page = 1;
    }
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      this.filtersInputs[key] = this.queries[key] ? this.queries[key] : '';
    });
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key !== 'validFilters') {
        this.filtersInputs[key] = '';
      }
    });
    this.resetScope();
    this.search();
  }

  search() {
    const queryData = { ...this.filtersInputs };
    delete queryData.validFilters;
    this.queries = { ...queryData } as unknown as Query;
    this.limit = this.limit || 10;
    this.page = 1;
    this.getCoursesList();
  }

  async getCoursesList() {
    try {
      this.loading = true;

      delete this.queries.desc;
      const reqQuery = {
        ...this.queries,
        limit: `${this.limit}`,
        page: `${this.page}`,
      };

      const sortData: SortData = {
        orderBy: this.sortKeys[0],
        clientLang: this.$store.state.lang.lang,
      };

      if (this.sortDesc[0]) {
        const [desc] = this.sortDesc;
        sortData.desc = desc.toString();
      }

      this.currentSelectedScope = { ...this.$store.state.scope };
      const { selectedScopeId } = this.currentSelectedScope;
      const {
        courses, currentPage, totalItems, courseTypes, rights,
      }: CoursesListResponse = await this.Provider.getAppCourseList(this.appId, {
        ...reqQuery, ...sortData, scope: selectedScopeId == null ? '' : selectedScopeId,
      });
      this.loading = false;
      this.loadingFilters = false;
      this.userRights = rights;
      this.courseList = courses;
      this.page = currentPage;
      this.totalItems = totalItems;

      if ((Number(this.totalItems) / this.limit) <= Number(this.page)) {
        reqQuery.page = `${Math.ceil((Number(this.totalItems) / this.limit))}`;
      }

      if (totalItems >= 50) {
        this.limitOption = [5, 10, 20, 50];
      }

      this.$store.commit('CourseFilters', { ...reqQuery, ...sortData });

      this.filtersOptions.courseTypes = courseTypes;
      this.$router.replace({ query: { ...reqQuery, ...sortData } });
      this.setQueries();
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.loading = false;
      this.loadingFilters = false;
      this.courseList = [];
    }
  }

  setQueries() {
    const route: Route = this.$route as Route;
    Object.assign(this.queries, route.query);
    this.limit = Number(this.queries.limit) || 10;
    this.page = Number(this.queries.page) || 1;
    this.tableLimit = Number(this.queries.limit) || 10;
    this.tablePage = Number(this.queries.page) || 1;
    if (this.queries.orderBy !== undefined) {
      this.sortKeys[0] = this.queries.orderBy;
    }
    if (this.queries.desc === 'true') {
      this.sortDesc[0] = true;
    }
  }

  getItemIndex(item) {
    return this.courseList.indexOf(item);
  }

  async onOptionUpdate(options) {
    this.page = options.page;
    this.limit = options.itemsPerPage;
    this.sortKeys = options.sortBy;
    this.sortDesc = options.sortDesc;
    await this.getCoursesList();
  }

  resetScope() {
    this.$store.commit('resetScope');
  }
}
