











































































/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { CourseTypes } from '@/types/course.type';
import { FiltersInputs } from '@/types/questions.type';

@Component
export default class CoursesListFiltersComponent extends Vue {
  @Prop(Boolean) readonly loading!: boolean;

  @Prop(Object) readonly filtersData!: any;

  @Prop(Object) readonly value!: FiltersInputs;

  get filtersInputs() {
    return this.value;
  }

  set filtersInputs(value) {
    this.$emit('input', value);
  }

  doSearch() {
    if (this.filtersInputs.validFilters) {
      this.$emit('search');
    }
  }

  get filters() {
    return {
      ...this.filtersData,
      courseTypes: this.filtersData.courseTypes.map((type: CourseTypes) => ({
        ...type,
        baName: this.$options.filters?.servertranslate(type.baName),
      })),
    };
  }

  rules = {
    courseName: [
      (val: string) => val.trim().length <= 100
        || this.$t('validations.maxLength', { max: 100 }),
    ],
    stdPlan: [
      (val: string) => val.trim().length <= 100
        || this.$t('validations.maxLength', { max: 100 }),
    ],
    IHId: [
      (val: string) => val.trim().length <= 100
        || this.$t('validations.maxLength', { max: 100 }),
    ],
  };
}
